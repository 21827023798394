import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import axios from 'src/utils/apiWP';
import thumbnail from 'src/assets/images/placeholder.webp';
import facebook from 'src/assets/images/icons/facebook.svg';
import instagram from 'src/assets/images/icons/instagram.svg';
import youtube from 'src/assets/images/icons/youtube.svg';

export default function BlogBlock() {

    const [posts, setPosts] = useState(null);

    const fetchPosts = async () => {
        axios.get(`https://blog.anageimoveis.com.br/wp-json/wp/v2/posts?_embed&page=1&per_page=2&search=`).then((response) => {
            if (response.request.status === 200) {
                setPosts(response.data);
            }
        }).catch((error) => {
        }).then(() => {
        });
    };

    const getThumbnail = (post) => {
        var elem = document.createElement('div');
        elem.style.display = 'none';
        elem.innerHTML = post.content.rendered;
        if(elem.querySelector('img')) {
            return elem.querySelector('img').src;
        }
        return '';
    }

    useEffect(() => {
        fetchPosts();
    }, []);

    return (
        <div className="blog-block">
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title-block">
                                        <h2 className="title title-2">Blog Anagê</h2>
                                        <p className="text text-1">Encontre dicas, informações e tire todas as suas dúvidas sobre o mercado imobiliário no Blog da Anagê Imóveis!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {posts && posts.map(post => (
                                    <div className="col-md-6">
                                        <RouterLink to={`/blog/${post.slug}`} className="blog-item" style={{ backgroundImage: 'url(' + getThumbnail(post) + ')' }}>
                                            <div>
                                                <h3 className="title">{post.title.rendered}</h3>
                                                <span>Ler mais</span>
                                            </div>
                                        </RouterLink>
                                    </div>
                                ))}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="social-media">
                                        <ul className="list">
                                            <li className="list-item">
                                                <a href="https://www.instagram.com/anageimoveis/" target="_blank" className="list-link instagram">
                                                    <img alt="" src={instagram} className="icon"></img>
                                                    <span>Siga</span>
                                                </a>
                                            </li>
                                            <li className="list-item">
                                                <a href="https://www.facebook.com/anage.imoveis/?ref=br_rs" target="_blank" className="list-link facebook">
                                                    <img alt="" src={facebook} className="icon"></img>
                                                    <span>Curta</span>
                                                </a>
                                            </li>
                                            <li className="list-item">
                                                <a href="https://www.youtube.com/user/anagetv" target="_blank" className="list-link youtube">
                                                    <img alt="" src={youtube} className="icon"></img>
                                                    <span>Inscreva-se</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}