import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/pt-br'

import search from 'src/assets/images/icons/search.svg';
import tag from 'src/assets/images/icons/label.svg';
import facebook from 'src/assets/images/icons/facebook.svg';
import instagram from 'src/assets/images/icons/instagram.svg';
import youtube from 'src/assets/images/icons/youtube.svg';

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
    let i = from;

    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

export default function ShowCaseContent({ currentPage, posts, categories, onFilter, paginator, pageNeighbours = 0, setPages, filters, currentCategory, currentCategoryType }) {

    let [valueInput, setValueInput] = useState('');

    pageNeighbours =
        typeof pageNeighbours === "number"
            ? Math.max(0, Math.min(pageNeighbours, 2))
            : 0;

    const createMarkup = (content) => {
        return {
            __html: content
        };
    };

    const handleChange = (e) => {
        const { value } = e.target;

        setValueInput(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onFilter(valueInput);
    };

    const fetchPageNumbers = () => {
        const totalPages = paginator.totalPages;
        const currentPage = paginator.currentPage;

        const totalNumbers = pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages = [];

            const leftBound = currentPage - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, totalPages];
        };

        return range(1, totalPages);
    };

    const getThumbnail = (post) => {
        var elem = document.createElement('div');
        elem.style.display = 'none';
        elem.innerHTML = post.content.rendered;
        if(elem.querySelector('img')) {
            return elem.querySelector('img').src;
        }
        return '';
    }

    let pages = fetchPageNumbers();

    const queryParams = new URLSearchParams(window.location.search);
    const generatePaginationUrl = (page) => {
        
        let paginationUrl = '/blog';
        if(currentCategory) {
            if(currentCategoryType == 'categories') {
                paginationUrl += '/categorias/'+currentCategory.slug;
            }
            else {
                paginationUrl += '/tags/'+currentCategory.slug;
            }
        }

        paginationUrl += '/page/'+page;
        
        if(queryParams.get('busca')) {
            paginationUrl += '?busca='+queryParams.get('busca');            
        } 

        return paginationUrl;
    }

    return (
        <div className="blog-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="top-blog">
                            <div className="block-title">
                                <h1 className="title title-1">Blog Anagê</h1>
                                <p className="text text-1">Acompanhe as principais novidades e dicas do mercado imobiliário de Joinville e região no blog da Anagê Imóveis.</p>
                            </div>
                            <form onSubmit={handleSubmit} className="form-search">
                                <div className="form-group">
                                    <img alt="" src={search} className="icon"></img>
                                    <input
                                        type="text"
                                        name="search"
                                        value={valueInput}
                                        className="form-control"
                                        placeholder="Faça uma pesquisa" 
                                        onChange={handleChange}
                                    />
                                    <button type="submit" className="btn btn-1 btn-search">Buscar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="items">
                            {posts.length > 0 && posts.map(post => {
                                return (
                                    <div className="item item-article" key={post.id}>
                                        <RouterLink to={`/blog/${post.slug}`}>
                                            <div className="img-post" 
                                                style={{ backgroundImage: 'url(' + getThumbnail(post) + ')' }}>
                                            </div>
                                            <h3 className="title title-6" dangerouslySetInnerHTML={createMarkup(post.title.rendered)}></h3>
                                            <div className="text text-2" dangerouslySetInnerHTML={createMarkup(post.excerpt.rendered)}></div>
                                        </RouterLink>
                                        <div className="content-category">
                                            <img alt="" src={tag} className="icon"></img>
                                            <span className="text text-5">{post['_embedded']['wp:term'] !== undefined && (
                                                post['_embedded']['wp:term'][0][0].name
                                            )}
                                            </span>
                                            <span className="text text-5">{ moment(post.date).format('DD/MM/YY') }</span>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="pagination">
                                <ul className="pagination-block">
                                    {paginator.currentPage > 1 && (
                                        <li className="page-number">
                                            <RouterLink
                                                to={{ pathname: `${generatePaginationUrl(paginator.currentPage-1)}` }}
                                                className="link"
                                            >
                                                <span className="icon icon-left-arrow"></span>
                                            </RouterLink>
                                        </li>
                                    )}
                                    {pages.map((page, i) => {
                                        if (page === LEFT_PAGE || page === RIGHT_PAGE) {
                                            return (
                                                <li key={i} className="page-number">
                                                    ...
                                                </li>
                                            );
                                        }

                                        return (
                                            <li className="page-number" key={i}>
                                                <RouterLink 
                                                    to={{ pathname: `${generatePaginationUrl(page)}` }}
                                                    className={`link ${paginator.currentPage === page ? " active" : ""}`}
                                                >
                                                    {page}
                                                </RouterLink>
                                            </li>
                                        );
                                    })}
                                    {paginator.currentPage < paginator.totalPages && (
                                        <li className="page-number">
                                            <RouterLink
                                                to={{ pathname: `${generatePaginationUrl(paginator.currentPage+1)}` }}
                                                className="link"
                                            >
                                                <span className="icon icon-right-arrow"></span>
                                            </RouterLink>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 offset-md-1">
                        <div className="block-filters">
                            <div className="filters">
                                <div className="filter">
                                    <h3 className="title title-2">Categorias</h3>
                                    <ul className="list">
                                        {categories.length > 0 && categories.map(category =>
                                            <li className="list-item" key={category.id}>
                                                <RouterLink
                                                    to={{ pathname: `/blog/categorias/${category.slug}`, state: { entityId: category.id, name: category.name } }}
                                                    className="list-link text text-5"
                                                    dangerouslySetInnerHTML={createMarkup(category.name)}
                                                />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
