import React, { useState, useEffect } from 'react';
import image1 from 'src/assets/images/anage-experiencia.webp';
import image2 from 'src/assets/images/anage-lojas.webp';
import image3 from 'src/assets/images/anage-variedade-imoveis.webp';
import image4 from 'src/assets/images/anage-atendimento.webp';
import image5 from 'src/assets/images/anage-oferta-imoveis@2x.png';
import image6 from 'src/assets/images/anage-negocios@2x.png';
import image7 from 'src/assets/images/anage-parceria@2x.png';
import image8 from 'src/assets/images/anage-social@2x.png';
import image9 from 'src/assets/images/anage-profissionais@2x.png';

import icon1 from 'src/assets/images/icons/icon-experiencia.svg';
import icon2 from 'src/assets/images/icons/icon-lojas.svg';
import icon3 from 'src/assets/images/icons/icon-variedade-imoveis.svg';
import icon4 from 'src/assets/images/icons/icon-atendimento.svg';
import icon5 from 'src/assets/images/icons/icon-oferta-imoveis.svg';
import icon6 from 'src/assets/images/icons/icon-negocios.svg';
import icon7 from 'src/assets/images/icons/008-like.svg';
import icon8 from 'src/assets/images/icons/icon-social.svg';
import icon9 from 'src/assets/images/icons/icon-profissionais.svg';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function InfoSlider({}) {
    const images = [
        {
            id: 1,
            url: image1,
            title: "Experiência de mais de três décadas no mercado imobiliário",
            icon: icon1
        },
        {
            id: 2,
            url: image2,
            title: "Quatro lojas estrategicamente localizadas em Joinville",
            icon: icon2
        },
        {
            id: 3,
            url: image3,
            title: "Maior variedade de imóveis para venda - prontos ou na planta",
            icon: icon3
        },
        {
            id: 4,
            url: image4,
            title: "Atendimento multicanal, com profissionais especialistas",
            icon: icon4
        },
        {
            id: 5,
            url: image5,
            title: "Maior oferta de imóveis para locação e uma estrutura especializada para atender proprietários e inquilinos",
            icon: icon5
        },
        {
            id: 6,
            url: image6,
            title: "Foco em fazer negócios com qualidade, agilidade e segurança",
            icon: icon6
        },
        {
            id: 7,
            url: image7,
            title: "Parceria com as melhores e maiores construtoras que atuam em Joinville e região",
            icon: icon7
        },
        {
            id: 8,
            url: image8,
            title: "Empresa socialmente engajada em ações filantrópicas",
            icon: icon8
        },
        {
            id: 9,
            url: image9,
            title: "Atendimento completo realizado por profissionais experientes e comprometidos com os resultados desejados pelo cliente",
            icon: icon9
        }
    ];

    return (
        <div className="slider-block"> 
            <h2 className="title title-2">
                Nossos Diferenciais
            </h2>
            <div className="container">
                <div className="position">
                    <Swiper 
                        spaceBetween={15}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        breakpoints={{
                            768: {
                                "slidesPerView": 3,
                            },
                        }}
                    >
                        {images.map((image, idx) => (
                            <SwiperSlide style={{ backgroundImage: 'url(' + image.url + ')' }}>
                                <h4 className="title title-4">
                                    <img alt="" src={image.icon} className="icon"></img>
                                    {image.title}
                                </h4>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
