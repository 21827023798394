import React, {
  forwardRef,
  useState,
  useEffect, useCallback
} from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { Link as RouterLink } from 'react-router-dom';

const Page = forwardRef(({
  title,
  description,
  breadcrumbs,
  children,
  ...rest
}, ref) => {
  const location = useLocation();
  const [metaTags, setMetaTags] = useState({
    title: '',
    description: ''
  });

  const { trackPageview } = useAnalytics();

  const initialize = useCallback(() => {

    let newTitle = 'Anagê Imóveis';
    if (title && title.length) {
      newTitle = title + ' - ' + newTitle;
    }

    let newDescription = description ?? '';
    newDescription = newDescription.substring(0, 155);

    setMetaTags({
      title: newTitle,
      description: newDescription
    });

    trackPageview(newTitle, location.pathname);

  }, [location]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet key="meta-description">
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
      </Helmet>

      {breadcrumbs && breadcrumbs.length && (
        <div className="breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ol>
                  {breadcrumbs.map((breadcrumb, key) => (
                    <li className={breadcrumbs.length == (key + 1) ? "breadcrumb-item active" : "breadcrumb-item"}><RouterLink to={breadcrumb.to}>{breadcrumb.title}</RouterLink></li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Page;
