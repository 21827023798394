import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import image from 'src/assets/images/nao-encontramos-a-pagina-anage.svg';

import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {}
}));

function PageNotFound() {
    const classes = useStyles();

    let pageTitle = 'Página não encontrada';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="error-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 offset-md-2">
                            <img alt="" src={image} className="image img-fluid"></img>
                        </div>
                        <div className="col-md-4">
                            <div className="description">
                                <div className="info-centered">
                                    <h2 className="title title-1">
                                        Esse conteúdo não está mais disponível.
                                    </h2>
                                    <p className="text text-1">
                                        Mas, continue em nosso site e confira outras novidades.
                                    </p>
                                    <div className="form-row">
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <RouterLink to="/" className="btn btn-1">
                                                Home
                                            </RouterLink>
                                        </div>
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <RouterLink to="/anuncie-seu-imovel" className="btn btn-7">
                                                Anunciar Imóvel
                                            </RouterLink>
                                        </div>
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <RouterLink to="/como-alugar" className="btn btn-7">
                                                Como Alugar
                                            </RouterLink>
                                        </div>
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <RouterLink to="/como-comprar" className="btn btn-7">
                                                Como Comprar
                                            </RouterLink>
                                        </div>
                                        <div className="col-8 col-md-12 col-lg-8">
                                            <RouterLink to="/perguntas-frequentes" className="btn btn-7">
                                                Perguntas Frequentes
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default PageNotFound;
