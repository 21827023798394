import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ManagerApi from 'src/services/managerApi';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function SecondaryBanner() {
    let params = {
        slidesPerView: 2,
        spaceBetween: 40,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };

    const generalInformation = useSelector((state) => state.interface.generalInformation);

    const [banners, setBanners] = useState([]);

    useEffect(() => {
        let newBanners = []
        generalInformation.banners.map( (banner) => {
            if(banner.type == 'home') {
                newBanners.push(banner)
            }
        });
        setBanners(newBanners);
    }, []);

    return (
        <div className="secondary-banners">
            <div className="container">
                <Swiper
                    spaceBetween={40}
                    slidesPerView={1}
                    pagination
                    breakpoints={{
                        576: {
                            "slidesPerView": 2,
                        },
                    }}
                >
                    {banners.length > 0 && banners.map((banner, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <a href={banner.link} style={{ backgroundImage: 'url(' + banner.imageDesktopUrl + ')' }} target="_blank"></a>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    );
}