import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, AppBar, Tabs, Tab, Box } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));
  
export default function SearchBlock() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className="searches-block">
            <div className="container">
                <Box>
                    <h2 className="title title-2">
                        Buscas Populares
                    </h2>
                </Box>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Imóveis para Alugar" {...a11yProps(0)} />
                        <Tab label="Imóveis para Comprar" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <Box
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex} 
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <div className="words-block popular-words">
                            <a href="#" className="btn btn-3">
                                Apartamento na planta em Joinville
                            </a>
                            <a href="#" className="btn btn-3">
                                Geminados na planta à venda em Joinville
                            </a>
                            <a href="#" className="btn btn-3">
                                Apartamento Minha Casa Minha Vida
                            </a>
                            <a href="#" className="btn btn-3">
                                Apartamento mobiliado para alugar em Joinville
                            </a>
                            <a href="#" className="btn btn-3">
                                Apartamento até R$ 200 mil
                            </a>
                            <a href="#" className="btn btn-3">
                                Apartamento no litoral de Santa Catarina
                            </a>
                            <a href="#" className="btn btn-3">
                                Casas para alugar em Joinville com piscina
                            </a>
                            <a href="#" className="btn btn-3">
                                Casa na planta para comprar
                            </a>
                            <a href="#" className="btn btn-3">
                                Imóveis comerciais em Joinville
                            </a>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <div className="words-block">
                            <a href="#" className="btn btn-3">
                                Apartamento na planta em Joinville
                            </a>
                            <a href="#" className="btn btn-3">
                                Geminados na planta à venda em Joinville
                            </a>
                            <a href="#" className="btn btn-3">
                                Apartamento Minha Casa Minha Vida
                            </a>
                            <a href="#" className="btn btn-3">
                                Apartamento mobiliado para alugar em Joinville
                            </a>
                            <a href="#" className="btn btn-3">
                                Apartamento até R$ 200 mil
                            </a>
                            <a href="#" className="btn btn-3">
                                Apartamento no litoral de Santa Catarina
                            </a>
                            <a href="#" className="btn btn-3">
                                Casas para alugar em Joinville com piscina
                            </a>
                            <a href="#" className="btn btn-3">
                                Casa na planta para comprar
                            </a>
                            <a href="#" className="btn btn-3">
                                Imóveis comerciais em Joinville
                            </a>
                        </div>
                    </TabPanel>
                </Box>
            </div>
        </div>
    );
}