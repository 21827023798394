import React, { useState } from 'react';

import {Link as RouterLink} from 'react-router-dom';

import phone from 'src/assets/images/icons/iphone.svg';
import handshake from 'src/assets/images/icons/handshake.svg';
import key from 'src/assets/images/icons/key.svg';
import user from 'src/assets/images/icons/user.svg';
import dollar from 'src/assets/images/icons/dollar-symbol.svg';
import house from 'src/assets/images/icons/home.svg';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function InfoModalities() {
    return (
        <div className="info-modalities">
            <div className="hidden-mobile">
                <div className="container">
                    <Swiper
                        className="slider-modalities"
                        spaceBetween={40}
                        slidesPerView={1}
                        navigation
                    >
                        <SwiperSlide>
                            <div className="block-info">
                                <h2 className="title title-2">
                                    Alugar Imóveis em Joinville e Região
                                </h2>
                                <h3 className="text text-1">
                                    Encontre seu novo lar ou imóvel comercial na Anagê
                                </h3>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={phone} className="icon"></img>
                                            <p>
                                                <strong>Facilidade: </strong>faça o seu processo de locação online
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={handshake} className="icon"></img>
                                            <p>
                                                <strong>Menos burocracia: </strong>possibilidade de alugar imóveis sem fiador
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={key} className="icon"></img>
                                            <p>
                                                <strong>Mais opções: </strong>diversos tipos de imóveis nos lugares que você procura
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <RouterLink to="/como-alugar" className="btn btn-1">Saiba mais como alugar</RouterLink>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="block-info">
                                <h2 className="title title-2">
                                    Comprar Imóveis em Joinville e Região
                                </h2>
                                <h3 className="text text-1">
                                    Imóveis residenciais e comerciais para você investir
                                </h3>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={user} className="icon"></img>
                                            <p>
                                                <strong>Consultoria: </strong>suporte para comprar seu imóvel com segurança
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={dollar} className="icon"></img>
                                            <p>
                                                <strong>Simulação: </strong>encontramos as melhores opções de financiamento para você
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={house} className="icon"></img>
                                            <p>
                                                <strong>Personalização: </strong>apresentamos os melhores imóveis de acordo com o seu perfil
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <RouterLink to="/como-comprar" className="btn btn-1">Saiba mais como comprar</RouterLink>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="show-mobile">
                <div className="container">
                    <div>
                        <div className="block-info">
                            <h2 className="title title-2">
                                Alugar Imóveis em Joinville e Região
                            </h2>
                            <h3 className="text text-1">
                                Encontre seu novo lar ou imóvel comercial na Anagê
                            </h3>
                            <Swiper
                                className="slider-info"
                                spaceBetween={40}
                                slidesPerView={1}
                                navigation
                                breakpoints={{
                                    768: {
                                        "slidesPerView": 3,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className="item">
                                        <img alt="" src={phone} className="icon"></img>
                                        <p>
                                            <strong>Facilidade: </strong>faça o seu processo de locação online
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <img alt="" src={handshake} className="icon"></img>
                                        <p>
                                            <strong>Menos burocracia: </strong>possibilidade de alugar imóveis sem fiador
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <img alt="" src={key} className="icon"></img>
                                        <p>
                                            <strong>Mais opções: </strong>diversos tipos de imóveis nos lugares que você procura
                                        </p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <RouterLink to="/como-alugar" className="btn btn-1">Saiba mais como alugar</RouterLink>
                        </div>
                    </div>
                    <div>
                        <div className="block-info">
                            <h2 className="title title-2">
                                Comprar Imóveis em Joinville e Região
                            </h2>
                            <h3 className="text text-1">
                                Imóveis residenciais e comerciais para você investir
                            </h3>
                            <Swiper
                                className="slider-info"
                                spaceBetween={40}
                                slidesPerView={1}
                                navigation
                                breakpoints={{
                                    768: {
                                        "slidesPerView": 3,
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className="item">
                                        <img alt="" src={user} className="icon"></img>
                                        <p>
                                            <strong>Consultoria: </strong>suporte para comprar seu imóvel com segurança
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <img alt="" src={dollar} className="icon"></img>
                                        <p>
                                            <strong>Simulação: </strong>encontramos as melhores opções de financiamento para você
                                        </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item">
                                        <img alt="" src={house} className="icon"></img>
                                        <p>
                                            <strong>Personalização: </strong>apresentamos os melhores imóveis de acordo com o seu perfil
                                        </p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <RouterLink to="/como-comprar" className="btn btn-1">Saiba mais como comprar</RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}