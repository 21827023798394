import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { Link as RouterLink } from 'react-router-dom';

import tenant from 'src/assets/images/area-do-inquilino.webp';
import owner from 'src/assets/images/area-do-proprietario.webp';

const useStyles = makeStyles(() => ({
    root: {}
}));

function CustomerArea() {
    const classes = useStyles();

    let pageTitle = 'Área do Cliente';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="client-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box-client" style={{ backgroundImage: 'url(' + tenant + ')' }}>
                                <div className="info-centered">
                                    <h3 className="title title-12">
                                        Sou Inquilino
                                    </h3>
                                    <RouterLink to="/area-do-cliente/sou-inquilino" className="btn btn-1">
                                        Acessar
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="box-client" style={{ backgroundImage: 'url(' + owner + ')' }}>
                                <div className="info-centered">
                                    <h3 className="title title-12">
                                        Sou Proprietário
                                    </h3>
                                    <RouterLink to="/area-do-cliente/sou-proprietario" className="btn btn-1">
                                        Acessar
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page >
    );
}

export default CustomerArea;
