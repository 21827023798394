import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import Page from 'src/components/Page';
import FaqContent from './FaqContent';
import Blog from 'src/components/AnageImoveis/Blog';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import ManagerApi from 'src/services/managerApi';

const useStyles = makeStyles(() => ({
    root: {}
}));

function Faq() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [faqCategories, setFaqCategories] = useState([]);
    const [faqs, setFaqs] = useState([]);

    const faqCategoriesApi = new ManagerApi('/faq/faqcategory');

    const fetchFaqCategories = async () => {
        const requestID = uuidv4();

        dispatch(addLoadingGlobal(requestID));

        faqCategoriesApi.getList().then((response) => {
            if (response.data.success) {
                setFaqCategories(response.data.content);
            }
        }).catch((error) => {
        }).then(() => {
            dispatch(removeLoadingGlobal(requestID));
        });
    }

    useEffect(() => {
        fetchFaqCategories();
    }, []);

    // const [faqs, setFaqs] = useState([]);

    const faqsApi = new ManagerApi('/faq/faq');

    const fetchFaqs = async () => {
        const requestID = uuidv4();

        dispatch(addLoadingGlobal(requestID));

        faqsApi.getList().then((response) => {
            if (response.data.success) {
                setFaqs(response.data.content);
            }
        }).catch((error) => {
        }).then(() => {
            dispatch(removeLoadingGlobal(requestID));
        });
    }

    useEffect(() => {
        fetchFaqs();
    }, []);

    let pageTitle = 'Perguntas Frequentes';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <FaqContent />
            <Blog />
            <Newsletter />
        </Page>
    );
}

export default Faq;
