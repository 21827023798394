import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import ContactContent from './ContactContent';
import Blog from 'src/components/AnageImoveis/Blog';
import Newsletter from 'src/components/AnageImoveis/Newsletter';

const useStyles = makeStyles(() => ({
    root: {}
}));

function Contact() {
    const classes = useStyles();

    let pageTitle = 'Entre em Contato';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <ContactContent />
            <Blog />
            <Newsletter />
        </Page>
    );
}

export default Contact;
