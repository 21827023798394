import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import SearchFilters from 'src/components/AnageImoveis/SearchFilters';
import SearchResults from 'src/components/AnageImoveis/SearchResults';
import GoogleMap from 'src/components/AnageImoveis/GoogleMaps/GoogleMap';
// import Pagination from '@material-ui/lab/Pagination';
import SwiperCore, {
  Navigation, Pagination, Scrollbar, A11y
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ManagerApi from 'src/services/managerApi';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import { useHistory } from 'react-router';
import slugify from 'slugify';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const useStyles = makeStyles(() => ({
  root: {}
}));

var filterActives = null;

function SearchResult({ filters, suggestion }) {
  const generalInformation = useSelector((state) => state.interface.generalInformation);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMapVisible, setIsMapVisible] = useState(false);
  const [realEstateFilters, setRealEstateFilters] = useState({ ...filters });
  const [realEstateSort, setRealEstateSort] = useState({
    realEstateType: 'ASC',
    priceRental: "ASC",
    priceSale: "ASC",
  });
  const [realEstates, setRealEstates] = useState(null);
  const [realEstatesLocations, setRealEstatesLocations] = useState([]);
  const [mapBounds, setMapBounds] = useState({});
  const [banners, setBanners] = useState([]);
  const [paginator, setPaginator] = useState({
    pageCurrent: 1,
    totalItemCount: null
  });

  const realEstateApi = new ManagerApi('/realestate');
  const realEstateLocationsApi = new ManagerApi('/realestate/realestatemap');

  const handlePaginate = (page) => {
    window.scrollTo(0, 0);
    const pageContent = document.getElementById('result-content');
    const mainHeader = document.getElementById('main-header');
    if (pageContent) {
      window.scrollTo(0, pageContent.offsetTop - mainHeader.offsetHeight - 30);
    }
    setPaginator({
      ...paginator,
      pageCurrent: page
    });
  };

  const handleFilter = (values) => {
    let statusLabel = '';

    filterActives = values;

    if (values.status == 'alugar') {
      statusLabel = 'alugar';
    } else {
      statusLabel = 'comprar';
      if (values.launch) {
        statusLabel = 'lancamentos';
      }
    }

    const url = [];
    let params = [];
    const validateParent = false;
    url.push(statusLabel);
    if (values.category) {
      generalInformation.categories[values.status.toLowerCase()].map((category) => {
        if (category.id == values.category) {
          url.push(category.slug);
        }
      });
    }
    if (values.city) {
      url.push(slugify(values.city.toLowerCase()));
    }
    if (values.neighbourhood) {
      const neighbourhoods = [...values.neighbourhood];
      neighbourhoods.sort();
      neighbourhoods.map((element, index) => {
        element = element.toLowerCase().replaceAll(' ', '');
        element = slugify(element);
        neighbourhoods[index] = element;
      });
      if (neighbourhoods.length) {
        url.push(neighbourhoods.join('-'));
      }
    }
    if (values.code) {
      params = `?code=${values.code}`;
    } else if (values.building) {
      params = `?building=${encodeURI(values.building)}`;
    }

    if (!suggestion) {
      history.push(`/${url.join('/')}${params}`);
    }
    

    setRealEstateFilters({ ...values });
  };

  const handleSort = (sort) => {
    const sortType = {
      priceAsc: {
        priceRental: 'ASC',
        priceSale: 'ASC',
      },
      priceDesc: {
        priceRental: 'DESC',
        priceSale: 'DESC',
      },
      areaAsc: { area: 'ASC' },
      areaDesc: { area: 'DESC' },
    };

    if (sortType[sort]) {
      setRealEstateSort(sortType[sort]);
    } else {
      setRealEstateSort(null);
    }
  };

  const fetchRealEstate = () => {
    const requestId = uuidv4();

    let query = { ...realEstateFilters, bounds: mapBounds };

    if (realEstateFilters.code) {
      query = { code: realEstateFilters.code };
    }

    if (!isMapVisible) {
      delete query.bounds;
      delete query.ids;
    }

    if (isMapVisible && !Object.keys(mapBounds).length) {
      return;
    }

    dispatch(addLoadingGlobal(requestId));

    if (paginator.pageCurrent == 1) {
      query.searchQuery = 1;
    }
    
    const validateRouteMostPrice = () => {

      if (filterActives === null) {
        if (
          filters.status === 'comprar' 
          && filters.building === null 
          && filters.code === null
          && filters.city === undefined
        ) {
          return true
        }
      }

      return false
    }


    realEstateApi.getList({
      ...query,
      paginator: {
        pageCurrent: paginator.pageCurrent,
        pageItemsPerPage: 20,
        sortOrder: realEstateSort,
        filters: {
          mostPrice: validateRouteMostPrice()
        }
      }
    }).then((response) => {
      if (response.data.success) {
        setRealEstates(response.data.content);
        setPaginator(response.data.paginator);
      }
    }).catch((error) => {
    }).finally(() => {
      dispatch(removeLoadingGlobal(requestId));
    });
  };

  const fetchRealEstateLocations = () => {
    if (!realEstateFilters.realStatesIds) {
      let query = { ...realEstateFilters };

      if (realEstateFilters.code) {
        query = { code: realEstateFilters.code };
      }

      realEstateLocationsApi.getList({
        ...query
      }).then((response) => {
        if (response.data.success) {
          const positions = response.data.content.locations.map((position) => ({
            id: position.id,
            lat: parseFloat(position.latitude),
            lng: parseFloat(position.longitude)
          }));
          setRealEstatesLocations(positions);
        }
      }).catch((error) => {
      });
    }
  };

  useEffect(() => {
    const newBanners = [];

    if (realEstateFilters.launch == 1) {
      generalInformation.banners.map((banner) => {
        if (banner.type == 'lancamento') {
          newBanners.push(banner);
        }
      });
    }

    setBanners(newBanners);
  }, [realEstateFilters]);

  useEffect(() => {
    fetchRealEstate();
  }, [realEstateFilters, realEstateSort, paginator.pageCurrent, mapBounds, isMapVisible]);

  useEffect(() => {
    if (isMapVisible && !realEstateFilters.ids) {
      fetchRealEstateLocations();
    }
  }, [realEstateFilters, isMapVisible]);

  let title = '';
  let categoryFilter = null;
  if (suggestion) {
    title = suggestion.name;
  }
  if (!title) {
    title = '';

    if (realEstateFilters.status == 'lancamentos') {
      title += 'Lançamentos';
    } else {
      if (realEstateFilters.category) {
        generalInformation.categories[realEstateFilters.status.toLowerCase()].map((category) => {
          if (category.id == realEstateFilters.category) {
            title += `${category.name} para ${realEstateFilters.status}`;
            categoryFilter = category;
          }
        });
      } else {
        title += `Imóveis para ${realEstateFilters.status}`;
      }

      const address = [];
      if (realEstateFilters.city) {
        address.push(realEstateFilters.city);
      }
      if (realEstateFilters.neighbourhood?.length) {
        address.push(realEstateFilters.neighbourhood.join(', '));
      }
      if (address.length) {
        title += ` em ${address.join(' - ')}`;
      }
    }
  }

  const mobileSize = window.innerWidth < 700;

  return (
    <Page
      className={classes.root}
      title={title}
    >
      <div id="result-content" className="result-content">

        <div className="container-fluid">
          <div className="row">

            {!mobileSize ? (
              <>
                <div className={isMapVisible || banners.length ? 'col-md-8 pr-0 toggle-animation' : 'col-md-12 toggle-animation'}>
                  <SearchFilters
                    filters={realEstateFilters}
                    onFilter={handleFilter}
                    onSort={handleSort}
                    showMapButton
                    showMap={isMapVisible}
                    onChangeMapVisibility={setIsMapVisible}
                  />
                  <div className={isMapVisible || banners.length ? 'container-fluid' : 'container'}>
                    <SearchResults
                      pageTitle={title}
                      realEstates={realEstates}
                      filters={realEstateFilters}
                      paginator={paginator}
                      onFilter={handleFilter}
                      onSort={handleSort}
                      onPaginate={handlePaginate}
                    />
                  </div>
                </div>
                {isMapVisible ? (
                  <div className="col-md-4">
                    <div className="right-side">
                      { realEstatesLocations.length && (
                        <GoogleMap
                          realEstatesLocations={realEstatesLocations}
                          bounds={mapBounds}
                          onChangeBounds={setMapBounds}
                          filters={realEstateFilters}
                          onChangeFilters={setRealEstateFilters}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  banners.length > 0 && (
                    <div className="col-md-4">
                      <div className="right-side">
                        <Swiper
                          slidesPerView={1}
                          pagination={{ clickable: true }}
                        >
                          {banners.map((banner, i) => (
                            <SwiperSlide key={i}>
                              <a href={banner.link} style={{ backgroundImage: `url(${banner.imageDesktopUrl})` }} target="_blank" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <>
                <div className="col-md-12">
                  <SearchFilters
                    filters={realEstateFilters}
                    onFilter={handleFilter}
                    onSort={handleSort}
                    showMapButton
                    showMap={isMapVisible}
                    onChangeMapVisibility={setIsMapVisible}
                  />
                  {isMapVisible && (
                    <div className="right-side">
                      { realEstatesLocations.length && (
                        <GoogleMap
                          realEstatesLocations={realEstatesLocations}
                          bounds={mapBounds}
                          onChangeBounds={setMapBounds}
                          filters={realEstateFilters}
                          onChangeFilters={setRealEstateFilters}
                        />
                      )}
                    </div>
                  )}
                  <div className={isMapVisible || banners.length ? 'container-fluid' : 'container'}>
                    <SearchResults
                      pageTitle={title}
                      realEstates={realEstates}
                      filters={realEstateFilters}
                      paginator={paginator}
                      onFilter={handleFilter}
                      onSort={handleSort}
                      onPaginate={handlePaginate}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Newsletter />
    </Page>
  );
}

export default SearchResult;
