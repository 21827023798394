import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class MapContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stores: [
                { latitude: -26.287364739619573, longitude: -48.89855027297574, local: "Koder Tecnologia" }
            ]
        }
    }

    displayMarkers = () => {
        return this.state.stores.map((store, index) => {
            return <Marker key={index} id={index} position={{
                lat: store.latitude,
                lng: store.longitude
            }}
            />
        })
    }

    render() {
        return (
            <Map
                google={this.props.google}
                zoom={10}
                initialCenter={{ lat: -26.260063826734548, lng: -48.967041162575605 }}
            >
                {this.displayMarkers()}
            </Map>

        );
    }
}

export default GoogleApiWrapper(
    (props) => ({
        apiKey: ''
    })
)(MapContainer)