import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassKnowMore from 'src/models/KnowMoreModels';
import { Link as RouterLink } from 'react-router-dom';
let classKnowMore = new ClassKnowMore();

const defaultFormShape = classKnowMore.getObjects;

export default function KnowMoreForm({realEstate}) {
    const dispatch = useDispatch();
    const { trackEventForm, getRefQueryParam } = useAnalytics();
    const contactApi = new ManagerApi('/realestate/realestateknowmore');

    const brokerInformation = useSelector((state) => state.interface.brokerInformation);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
        acceptPolicy: Yup.string().required('Campo obrigatório.'),
        months: Yup.string().required('Campo obrigatório.'),
    });
    
    const styleSuccess = {
        color:'#4ec43c',
        textAlign:'center'
    }

    const styleDeny = {
        color:'#ef1d4d',
        textAlign:'center'
    }

    return (
        <div>
            <Formik
            initialValues={defaultFormShape}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values , {resetForm}) => {

                if(!values.acceptPolicy) {
                    dispatch(addMessage('Para prosseguir, você precisa aceitar os termos de envio.', 'error'));
                    return;
                }

                let data = new FormData();
                data = values;
                data.realEstateCode = realEstate.code;

                if (getRefQueryParam('utm_source')) {
                    data.utm_source = getRefQueryParam('utm_source');
                } else {
                    data.utm_source = '';
                }
                    
                if (getRefQueryParam('utm_medium')) {
                    data.utm_medium = getRefQueryParam('utm_medium');
                } else {
                    data.utm_medium = '';
                }
            
                if (getRefQueryParam('utm_campaign')) {
                    data.utm_campaign = getRefQueryParam('utm_campaign');
                } else {
                    data.utm_campaign = '';
                }

                if (getRefQueryParam('utm_term')) {
                    data.utm_term = getRefQueryParam('utm_term');
                } else {
                    data.utm_term = '';
                }
                    
                if (getRefQueryParam('utm_content')) {
                    data.utm_content = getRefQueryParam('utm_content');
                } else {
                    data.utm_content = '';
                }

                if (getRefQueryParam('utm_id')) {
                    data.utm_id = getRefQueryParam('utm_id');
                } else {
                    data.utm_id = '';
                }

                console.log('Valores form');
                console.log(data);

                if(brokerInformation) {
                    data.broker = brokerInformation.broker.id;
                }

                dispatch(addLoadingGlobal());
                contactApi.post(data).then(response => {

                    dispatch(removeLoadingGlobal());
                    if (response.data.success) {
                        dispatch(addMessage('Mensagem enviada com sucesso.', 'success'));
                        resetForm();
                        //trackEvent('Envio de formulário', 'Interessado em '+realEstate.status, '');
                        trackEventForm('Envio de formulário', 'Interessado em '+realEstate.status, 'form-anage-'+realEstate.status);
                    } else {
                        dispatch(addMessage('Houve um problema! Tente mais tarde.', 'error'));
                    }
                });
            }}
        >
            { ({ handleBlur, handleChange, setFieldValue, values }) => (
            <FormikForm className="form" id={'form-anage-'+realEstate.status}>
                <div className="form-group">
                    <label htmlFor="infoForm-name" className="label-control">Nome</label>
                    <Field type="text" className="form-control" id="infoForm-name" onBlur={handleBlur} onChange={handleChange} name="name" placeholder="Digite seu nome" />
                    <ErrorMessage component="span" name="name" className="error-message" />
                </div>
                <div className="form-group">
                    <label htmlFor="infoForm-email" className="label-control">E-mail</label>
                    <Field type="email" className="form-control" id="infoForm-email" onBlur={handleBlur} onChange={handleChange} name="email" placeholder="exemplo@email.com.br" />
                    <ErrorMessage component="span" name="email" className="error-message" />
                </div>
                <div className="form-group">
                    <label htmlFor="infoForm-phone" className="label-control">DDD + Telefone/WhatsApp</label>
                    <Field type="text" className="form-control" id="infoForm-phone" onBlur={handleBlur} onChange={handleChange} name="phone" placeholder="(00) 00000-00000" />
                    <ErrorMessage component="span" name="phone" className="error-message" />
                </div>
                <div className="form-group">
                    <label htmlFor="infoForm-months" className="label-control">Em quanto tempo pretende se mudar?</label>
                    <Field as="select" name="months" className="form-control custom-select" id="infoForm-months" onBlur={handleBlur} onChange={handleChange} >
                        {realEstate.status == 'alugar' && (
                            <>
                            <option>Selecione</option>
                            <option value="Em até 30 dias">Em até 30 dias</option>
                            <option value="Em até 60 dias">Em até 60 dias</option>
                            <option value="Mais de 90 dias">Mais de 90 dias</option>
                            </>
                        )}

                        {realEstate.status == 'comprar' && (
                            <>
                                <option>Selecione</option>
                                <option value="Menos de 6 meses">Menos de 6 meses</option>
                                <option value="6 meses - 1 ano">6 meses - 1 ano</option>
                                <option value="1 ano - 3 anos">1 ano - 3 anos</option>
                                <option value="3 anos ou mais">3 anos ou mais</option>
                            </>
                        )}
                    </Field>
                    <ErrorMessage component="span" name="months" className="error-message" />
                </div>

                <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('acceptPolicy',!values.acceptPolicy) } }>
                    <input type="checkbox" name="acceptPolicy" className="custom-control-input" id="acceptPolicy" checked={values.acceptPolicy}/>
                    <label className="custom-control-label" htmlFor="acceptPolicy">Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>, nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o contato comercial da Anagê Imóveis</label>
                </div>
                <button type="submit" name="button" className="btn btn-1">Enviar</button>
            </FormikForm>
            )}
        </Formik>
        </div>
    );
}