import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';

import icon from 'src/assets/images/icons/icon-coletados.svg';
import icon2 from 'src/assets/images/icons/icon-compartilhados.svg';
import icon3 from 'src/assets/images/icons/icon-protegidos.svg';
import icon4 from 'src/assets/images/icons/icon-utilizados.svg';

const useStyles = makeStyles(() => ({
    root: {}
}));

function PrivacyPolicy() {
    const classes = useStyles();

    let pageTitle = 'Política de Privacidade';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="policy-content">
                <div className="info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="description">
                                    <div className="block-title">
                                        <h1 className="title title-1">
                                            Política de Privacidade Anagê Imóveis
                                        </h1>
                                    </div>
                                    <h2 className="title title-4">
                                        Esta página é dedicada a explicar como seus dados pessoais são:
                                    </h2>
                                    <div className="items">
                                        <div className="item">
                                            <div className="block">
                                                <img alt="" src={icon} className="icon"></img>
                                                <div className="title title-5">Coletados</div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="block">
                                                <img alt="" src={icon4} className="icon"></img>
                                                <div className="title title-5">Utilizados</div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="block">
                                                <img alt="" src={icon3} className="icon"></img>
                                                <div className="title title-5">Protegidos</div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="block">
                                                <img alt="" src={icon2} className="icon"></img>
                                                <div className="title title-5">Compartilhados</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Introdução:
                                        </h2>
                                        <p className="text text-1">
                                            Esta política de privacidade contém informações sobre como são
                                            tratados, total ou parcialmente, de forma automatizada ou não, os dados
                                            pessoais dos usuários que acessam o site da Anagê Imóveis. Além disso,
                                            esta política tem como intuito informar os direitos do usuário e esclarecer
                                            quando e como poderá exercê-los, mas, caso ainda tenha dúvidas, nossos
                                            canais de atendimento indicados abaixo estão sempre à sua inteira
                                            disposição.
                                        </p>
                                        <p className="text text-1">
                                            A Política de Privacidade da Anagê Imóveis foi criada para evidenciar
                                            o seu compromisso em sempre tratar os dados pessoais de seus clientes e
                                            parceiros com segurança, privacidade e transparência, objetivos principais
                                            da Lei Geral de Proteção de Dados.
                                        </p>
                                        <p className="text text-1">
                                            A Lei Geral de Proteção de Dados - LGPD (Lei nº 13.709/18), é a
                                            legislação que estabelece como os dados pessoais de um titular devem ser
                                            coletados, tratados e até mesmo descartados.
                                        </p>
                                        <p className="text text-1">
                                            Portanto, esta Política de Privacidade descreve quais dados pessoais
                                            são coletados, como eles são tratados – utilizados, armazenados e
                                            compartilhados quando do seu acesso ao site, e os seus direitos em relação
                                            a esses dados.
                                        </p>
                                        <p className="text text-1">
                                            Recomendamos a leitura atenta.
                                        </p>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-6">
                                            <h4 className="title title-3">
                                                Dados pessoais
                                            </h4>
                                            <div className="data">
                                                <p className="text text-1">
                                                    Nome
                                                </p>
                                                <p className="text text-1">
                                                    E-mail
                                                </p>
                                                <p className="text text-1">
                                                    CPF
                                                </p>
                                                <p className="text text-1">
                                                    Telefone
                                                </p>        
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h4 className="title title-3">
                                                Dados sensíveis
                                            </h4>
                                            <div className="data">
                                                <p className="text text-1">
                                                    Religião
                                                </p>
                                                <p className="text text-1">
                                                    Orientação Sexual
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            O que são dados pessoais?
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Dados pessoais são quaisquer informações relacionadas à pessoa
                                        natural identificada ou identificável, ou seja, são dados pessoais aqueles
                                        que permitam identificar uma pessoa, tais como: nome, data de
                                        nascimento, número de identificação, dados de localização e outros.
                                    </p>
                                    <p className="text text-1">
                                        É considerada identificável uma pessoa singular que possa ser
                                        identificada, direta ou indiretamente, em especial por referência a um
                                        identificador, como por exemplo, um nome, um número de identificação,
                                        dados de localização, identificadores por via eletrônica ou a um ou mais
                                        elementos específicos da identidade física, fisiológica, genética, mental,
                                        econômica, cultural ou social dessa pessoa.
                                    </p>
                                    <p className="text text-1">
                                        Se existir alguma informação além destas citadas, que seja capaz de
                                        identificar uma pessoa natural, ela também é considerada dado pessoal, por
                                        apresentar essa característica identificadora.
                                    </p>
                                    <p className="text text-1">
                                        Não são considerados dados pessoais aqueles que foram
                                        anonimizados (dados anônimos), uma vez que após a realização deste
                                        processo, não é possível a identificação do titular por qualquer meio.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            O que é o tratamento de dados pessoais?
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        O tratamento de dados pode ser definido como qualquer atividade
                                        que use um dado pessoal na sua operação, seja na coleta, produção,
                                        recepção, classificação, utilização, acesso, reprodução, transmissão,
                                        distribuição, processamento, arquivamento, armazenamento, e até mesmo
                                        a simples posse de um dado pessoal já é caracterizada como tratamento de
                                        dados.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Quem controla os meus dados?
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        O controlador é aquele a quem compete as decisões sobre o
                                        tratamento de dados pessoais. O controlador pode ser uma pessoa física ou
                                        jurídica, e neste caso, são as empresas que integram o Anagê Imóveis.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            O que fazemos com suas informações?
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Coletamos informações sobre quais páginas você acessa, as
                                        informações sobre o dispositivo móvel pelo qual você acessa o nosso site
                                        (tipo de dispositivo ou tipo de navegador) e informações que você nos envia
                                        (como o endereço de e-mail usado para receber nossos materiais).
                                    </p>
                                    <p className="text text-1">
                                        Quando você acessa o nosso site: 
                                    </p>
                                    <p className="text text-1">
                                        (1) podemos coletar e armazenar seu endereço IP;
                                    </p>
                                    <p className="text text-1">
                                        (2) podemos usar essas informações para combater o spam e outros
                                        abusos; e/ou
                                    </p>
                                    <p className="text text-1">
                                        (3) para gerar informações agregadas e não-identificadoras sobre
                                        como as pessoas acessam o nosso portal.
                                    </p>
                                    <p className="text text-1">
                                        As informações coletadas também poderão ser utilizadas para
                                        direcionamento de campanhas publicitárias de uma forma geral, além de
                                        formar dados estatísticos para fins comerciais, ou informativos, e que
                                        poderão ser compartilhados com parceiros ou outras empresas externas.
                                        Nesses casos, nós não revelaremos seu nome ou dados de navegação. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Do dever de não fornecer dados de terceiros:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Durante a utilização do site, o cliente compreende que não deverá,
                                        em hipótese alguma, fornecer dados de terceiros, sob pena de ser
                                        responsabilizado, conforme a legislação vigente.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Como obtemos informações sobre você:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Quando você nos fornece as suas informações pessoais para
                                        preencher um formulário, receber e-mails gratuitamente, receber
                                        informações sobre imóveis, pedir contato, anunciar imóvel, participar de
                                        webinars, acessar e-books e outros materiais digitais, você está
                                        concordando com a nossa coleta e uso de informações pessoais apenas
                                        para esses fins específicos.
                                    </p>
                                    <p className="text text-1">
                                        O objetivo destas informações é viabilizar o contato com da
                                        companhia com o cliente e/ou futuro cliente, de acordo com a sua
                                        solicitação, questionamento, sugestão, etc. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            O que acontece se você não nos fornece estes dados:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Determinados dados são essenciais para perfectibilização do acesso e
                                        utilização de nossos serviços e site, sempre visando um maior benefício a
                                        você, titular.
                                    </p>
                                    <p className="text text-1">
                                        Desta forma, caso você opte por não os fornecer, poderá ocorrer uma
                                        impossibilidade no acesso e utilização dos nossos serviços e site.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Do tempo de duração do tratamento destes dados?
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Os dados são mantidos apenas enquanto forem úteis para cumprir o
                                        propósito para qual foram coletados (conforme o princípio da necessidade,
                                        segundo a LGPD). 
                                    </p>
                                    <p className="text text-1">
                                        Entretanto, existem casos em que podemos manter estes dados pelo
                                        prazo necessário para cumprimento de obrigações legais, sendo que nestes
                                        casos eles não estarão em uso, mas sim armazenados para eventual
                                        necessidade de cumprimento de uma obrigação legal.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Como posso revogar o meu consentimento?
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Se depois de informar seus dados voluntariamente você queira
                                        revogar eventual consentimento concedido, você pode revogá-lo e evitar
                                        que entremos em contato para obter ou divulgar informações. Entre em
                                        contato conosco por meio do endereço
                                        controledeprivacidade@anageimoveis.com.br.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Do compartilhamento de dados:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Em nenhuma hipótese vendemos os dados e informações que você
                                        nos fornece. Também, não partilhamos seus dados para fins de marketing,
                                        além do marketing da própria companhia e seu consentimento, que,
                                        baseado nas suas preferências, o comunica em casos de eventuais
                                        promoções, campanhas, eventos e afins, visando sempre o seu benefício.
                                    </p>
                                    <p className="text text-1">
                                        <strong>
                                            Nenhum dado utilizado pelo setor de marketing da Anagê
                                            Imóveis foi captado sem que tenha sido informado pelo próprio
                                            titular.
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Do compartilhamento de dados para cumprimento de
                                            obrigação legal:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Existem algumas circunstâncias em que somos obrigados legalmente
                                        a compartilhar informações, como por exemplo, para o cumprimento de
                                        ordens e/ou obrigações legais ou cooperação com a autoridade nacional de
                                        proteção de dados.
                                    </p>
                                    <p className="text text-1">
                                        Em qualquer hipótese acima iremos nos certificar de que existe uma
                                        das dez bases legais para compartilhar as informações, conforme previsto
                                        pela LGPD em seu artigo 7º.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Dos links de terceiros:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Caso seja fornecido eventual link para sites de terceiros, a Anagê
                                        Imóveis não se responsabiliza pela forma de tratamento e processamento
                                        destes, não estando a presente política de privacidade aplicada à eles.
                                    </p>
                                    <p className="text text-1">
                                        Importante observar que estes sites, de terceiros, possuem políticas
                                        próprias de privacidade, sob as quais não temos qualquer tipo de controle.
                                        Portanto, não possuímos qualquer responsabilidade ou obrigação quanto a
                                        eles e lhe incentivamos a ler suas políticas de privacidade.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Das medidas de segurança no tratamento dos dados pessoais
                                            dos usuários:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Para proteger suas informações pessoais, tomamos precauções e
                                        seguimos as melhores práticas do mercado para nos certificar que elas não
                                        sejam perdidas, usurpadas, acessadas, divulgadas, alteradas ou destruídas.
                                    </p>
                                    <p className="text text-1">
                                        Os seus dados são acessados somente por profissionais devidamente
                                        autorizados, respeitados os princípios de proporcionalidade, necessidade e
                                        relevância para os nossos objetivos, além do compromisso de
                                        confidencialidade e preservação da privacidade nos termos desta política.
                                    </p>
                                    <p className="text text-1">
                                        Ainda, nos comprometermos a tratar os dados pessoais do usuário
                                        com a devida confidencialidade e dentro dos limites legais.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Cookies:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        A Anagê utiliza Cookies em suas plataformas, com os quais você
                                        concorda assim que acessa a sua página. 
                                    </p>
                                    <p className="text text-1">
                                        Os cookies são pequenos arquivos de texto que são colocados no seu
                                        computador pelos sites que você visita. Eles são amplamente usados para
                                        fazer com que os sites funcionem ou funcionem com mais eficiência, além
                                        de fornecer informações aos proprietários do site. 
                                    </p>
                                    <p className="text text-1">
                                        Os cookies são utilizados para armazenar suas preferências e outras
                                        informações sobre o computador do usuário; para poupar tempo do usuário
                                        eliminando a necessidade de inserir as mesmas informações várias vezes;
                                        para facilitar e assegurar as funções técnicas necessárias; para tornar a
                                        página web mais amigável ao usuário; bem como, para atender às
                                        necessidades do usuário ou avaliar visitas ao site para finalidades de
                                        marketing e otimização.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Como a Anagê utiliza esses cookies?
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Utilizamos cookies para armazenar e monitorar informações e
                                        preferências de nossos usuários.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Alterações da Política de Privacidade:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Reservamos o direito de modificar essa política de privacidade a
                                        qualquer momento. Portanto, por favor, leia-a com frequência. As alterações
                                        e esclarecimentos surtem efeito imediatamente após serem publicadas no
                                        site.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Fale conosco:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Se após a leitura desta Política de Privacidade você ainda tiver
                                        qualquer dúvida, ou por qualquer razão precisar se comunicar conosco para
                                        assuntos envolvendo os seus dados pessoais, você pode entrar em contato
                                        com a Anagê.
                                    </p>
                                    <p className="text text-1">
                                        Ainda, se você gostaria de acessar, corrigir, alterar ou excluir
                                        quaisquer informações pessoais que temos sobre você, registre uma queixa,
                                        ou simplesmente peça mais informações de contato através do endereço
                                        eletrônico controledeprivacidade@anageimoveis.com.br.
                                    </p>
                                    <p className="text text-1">
                                        Estamos sempre à disposição para esclarecer suas dúvidas e colocar
                                        você no controle dos seus dados pessoais.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block">
                                    <div className="block-title">
                                        <h2 className="title title-11">
                                            Da Legislação e Jurisdição:
                                        </h2>
                                    </div>
                                    <p className="text text-1">
                                        Esta Política de Privacidade e a relação decorrente das ações aqui
                                        compreendidas, assim como qualquer disputa que surja em virtude deste
                                        documento será regulada exclusivamente pela legislação brasileira.
                                    </p>
                                    <p className="text text-1">
                                        Fica eleito o Foro Central da Comarca de Joinville/SC, para dirimir
                                        qualquer questão envolvendo o presente documento. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="block-title">
                                            <h2 className="title title-1">
                                                Perguntas e Informações de Contato
                                            </h2>
                                        </div>
                                        <p className="text text-1">
                                            Se você gostaria de acessar, corrigir, alterar ou excluir quaisquer informações pessoais que temos sobre você, registre uma queixa, ou simplesmente peça mais informações de contato através do anage@anageimoveis.com.br ou por correio: Anagê Imóveis (R. Max Colin, 285 - Centro - Joinville/SC CEP 89201-215).
                                        </p>
                                    </div>
                                    <div className="col-md-5 offset-md-1">
                                        <div className="box">
                                            <img alt="" src={icon3} className="icon"></img>
                                            <div className="title title-2">
                                                Para o Anagê, proteger sua privacidade é mais que uma obrigação legal.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default PrivacyPolicy;
