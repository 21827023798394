import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import { ErrorMessage, Formik, Form as FormikForm, Field, Label } from 'formik';
import * as Yup from 'yup';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassContact from 'src/models/FindPropertyModels';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { ThemeConsumer } from 'styled-components';

let classContact = new ClassContact();
const defaultFormShape = classContact.getObjects;

const useStyles = makeStyles(() => ({
    root: {}
}));

function FindProperty() {
    const classes = useStyles();
    const { trackEvent } = useAnalytics();
    const dispatch = useDispatch();
    const contactApi = new ManagerApi('/contact/findyourrealestate');

    const validationSchema = Yup.object().shape({
        status: Yup.string().required('Campo obrigatório.'),
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
        city: Yup.string().required('Campo obrigatório.'),
        category: Yup.string().required('Campo obrigatório.')
    });
    
    const styleSuccess = {
        color:'green',
        textAlign:'center'
    }

    const styleDeny = {
        color:'red',
        textAlign:'center'
    }

    let pageTitle = 'Encontrar Meu Imóvel';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="find-property">
                <div className="container">
                    <div className="description">
                        <h1 className="title title-1">
                            A Anagê ajuda você a encontrar seu imóvel
                        </h1>
                        <p className="text text-1">
                            Você que está procurando um imóvel para comprar ou alugar, a Anagê Imóveis ajuda você a realizar o melhor negócio! Preencha os campos a seguir para entrarmos em contato e te ajudar a encontrar o seu imóvel.
                        </p>
                        <a href="#solicitar-atendimento" className="btn btn-1">
                            Solicitar Atendimento
                        </a>
                    </div>
                    <div className="steps">
                        <div className="item">
                            <div className="step">1</div>
                            <h3 className="title title-3">
                                Conte o tipo de imóvel que você procura
                            </h3>
                            <p className="text text-1">
                                Preencha o formulário e nos conte o perfil de imóvel que você deseja.
                            </p>
                        </div>
                        <div className="item">
                            <div className="step">2</div>
                            <h3 className="title title-3">
                                Vamos selecionar alguns imóveis
                            </h3>
                            <p className="text text-1">
                                Nossos especialista irão entrar em contato para apresentar imóveis do seu interesse.
                            </p>
                        </div>
                        <div className="item">
                            <div className="step">3</div>
                            <h3 className="title title-3">
                                Agende suas visitas
                            </h3>
                            <p className="text text-1">
                                Depois de escolher os imóveis que você mais gostou, agende um horário para visitá-los.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="find-property-form" id="solicitar-atendimento">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <Formik
                                initialValues={defaultFormShape}
                                enableReinitialize
                                validationSchema={validationSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={(values , {resetForm, setStatus}) => {
                                    let data = new FormData();
                                    data = values;

                                    if(!values.acceptPolicy) {
                                        dispatch(addMessage('Para prosseguir, você precisa aceitar os termos de envio.', 'error'));
                                        return;
                                    }

                                    dispatch(addLoadingGlobal());
                                    contactApi.post(data).then(response => {
                                        dispatch(removeLoadingGlobal());

                                        if (response.data.success == true) {
                                            dispatch(addMessage('Registro realizado com sucesso.', 'success'));
                                            resetForm();
                                            trackEvent('Envio de formulário', 'Encontre seu imóvel', '')
                                        }
                                        else {
                                            dispatch(addMessage('Não foi possível enviar o contato, por favor tente novamente mais tarde.', 'error'));
                                        }
                                    });
                                }}
                            >
                                { ({ values, setFieldValue, handleBlur, handleChange, status }) => (
                                <FormikForm className="form">
                                    <h3 className="title title-3">Dados Pessoais</h3>
                                    <div className="form-group">
                                        <label htmlFor="propertyForm-name" className="label-control">Nome</label>
                                        <Field type="text" className="form-control" id="propertyForm-name" onBlur={handleBlur} onChange={handleChange} name="name" placeholder="Digite seu nome" />
                                        <ErrorMessage component="span" className="error-message" name="name" />
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label htmlFor="propertyForm-email" className="label-control">E-mail</label>
                                                <Field type="email" className="form-control" id="propertyForm-email" onBlur={handleBlur} onChange={handleChange} name="email" placeholder="exemplo@email.com.br" />
                                                <ErrorMessage component="span" className="error-message" name="email" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="propertyForm-phone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                <Field type="text" className="form-control" id="propertyForm-phone" onBlur={handleBlur} onChange={handleChange} name="phone" placeholder="(00) 00000-00000" />
                                                <ErrorMessage component="span" className="error-message" name="phone" />
                                            </div>
                                        </div>
                                    </div>

                                    <h3 className="title title-3">Dados do imóvel</h3>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <div className="normal-group form-group black-group">
                                                <select className="form-control custom-select" id="modalitySelect" name="status" onBlur={handleBlur} onChange={handleChange}>
                                                    <option value="Aluguel">Aluguel</option>
                                                    <option value="Venda">Venda</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="propertyForm-city" className="label-control">Bairro ou cidade</label>
                                                <Field type="text" name="city" className="form-control" id="propertyForm-city" onBlur={handleBlur} onChange={handleChange} placeholder="Informe o bairro ou cidade" />
                                                <ErrorMessage component="span" className="error-message" name="city" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="propertyForm-category" className="label-control">Tipo do móvel</label>
                                                <Field type="text" name="category" className="form-control" id="propertyForm-category" onBlur={handleBlur} onChange={handleChange} placeholder="Casa, Apartamento, Terreno..." />
                                                <ErrorMessage component="span" className="error-message" name="category" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="propertyForm-priceRange" className="label-control">Faixa de preço (R$)</label>
                                                <Field type="text" name="priceRange" className="form-control" id="propertyForm-priceRange" onBlur={handleBlur} onChange={handleChange} placeholder="Informe a faixa de preço" />
                                                <ErrorMessage component="span" className="error-message" name="priceRange" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label htmlFor="propertyForm-privateArea" className="label-control">Área Privativa (m²)</label>
                                                <Field type="text" name="privateArea" className="form-control" id="propertyForm-privateArea" onBlur={handleBlur} onChange={handleChange} placeholder="Informe a área privativa" />
                                                <ErrorMessage component="span" className="error-message" name="privateArea" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <div className="block-items">
                                                        <p className="text text-1">
                                                            Quartos
                                                        </p>
                                                        <div className="actions">
                                                            {['1', '2', '3', '4'].map((item,key) => {
                                                                let label = item+'+';
                                                                let className = "btn btn-small";
                                                                if (item === values.bedrooms)
                                                                    className = className + ' active';

                                                                return (
                                                                    <button key={key} type="button" className={className} onClick={() => { setFieldValue('bedrooms', item) }}>
                                                                        {label}
                                                                    </button>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="block-items">
                                                        <p className="text text-1">
                                                            Vagas Garagem
                                                        </p>
                                                        <div className="actions">
                                                            {['1', '2', '3', '4'].map((item,key) => {
                                                                let label = item+'+';
                                                                let className = "btn btn-small";
                                                                if (item === values.parkingSpaces)
                                                                    className = className + ' active';

                                                                return (
                                                                    <button key={key} type="button" className={className} onClick={() => { setFieldValue('parkingSpaces', item) }}>
                                                                        {label}
                                                                    </button>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="normal-group form-group">
                                        <Field className="form-control" as="textarea" id="contactMessage" name="comments" placeholder="Observações adicionais" />
                                        <ErrorMessage component="span" className="error-message" name="comments" />
                                    </div>
                                    <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('acceptPolicy',!values.acceptPolicy) } }>
                                        <input type="checkbox" className="custom-control-input" name="acceptPolicy" checked={values.acceptPolicy} />
                                        <label className="custom-control-label"> {values.acceptPolicy} Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>, nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o contato comercial da Anagê Imóveis</label>
                                    </div>
                                    <button type="submit" className="btn btn-1">Enviar Solicitação</button>
                                </FormikForm>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default FindProperty;
