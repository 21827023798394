import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from 'src/components/Logo';
import Menu from 'src/layouts/MainLayout/Menu';
import 'src/theme/index.css';

import like from 'src/assets/images/icons/like.svg';
import area from 'src/assets/images/icons/area-do-cliente.svg';
import { Link } from '@material-ui/core';

import FavoriteModal from 'src/components/AnageImoveis/FavoriteModal.js';

const detectMob = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

function Header() {
    const dispatch = useDispatch();

    return (
        <div id="main-header" className="header">
            <span className="creci-header">
                CRECI 1216-J
            </span>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <RouterLink to="/" className="navbar-brand">
                        <Logo />
                    </RouterLink>
                    <div className='collapse navbar-collapse' id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto" >
                            <li className="nav-item">
                                <RouterLink
                                    to="/institucional"
                                    className="nav-link"
                                >
                                    Sobre Nós
                                </RouterLink>
                            </li>
                            <li className="nav-item">
                                <RouterLink
                                    to="/contato"
                                    className="nav-link"
                                >
                                    Contato
                                </RouterLink>
                            </li>
                            <li className="nav-item">
                                <RouterLink
                                    to="/anuncie-seu-imovel"
                                    className="nav-link"
                                >
                                    Anuncie
                                </RouterLink>
                            </li>
                            <li className="nav-item">
                                <Link
                                    href="/comprar/apartamento/joinville"
                                    className="nav-link"
                                >
                                    Comprar
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    href="/permutar"
                                    className="nav-link"
                                >
                                    Permutar
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    href="/voce-encontra-na-anage"
                                    className="nav-link"
                                >
                                    Oportunidades
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    href="/alugar/apartamento/joinville"
                                    className="nav-link"
                                >
                                    Alugar
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link
                                    href="#"
                                    target="_blank"
                                    className="nav-link"
                                >
                                    2ª via boleto
                                </Link>
                            </li> */}
                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <RouterLink
                                    to="/imoveis-favoritos"
                                    className="nav-link upper"
                                >
                                    <img alt="" src={like} className="icon icon-like"></img>
                                    FAVORITOS
                                </RouterLink>
                                <FavoriteModal />
                            </li>
                            <li className="nav-item">
                                <RouterLink
                                    to="/area-do-cliente"
                                    className="nav-link upper"
                                >
                                    <img alt="" src={area} className="icon"></img>
                                    ÁREA DO CLIENTE
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                    <Menu />
                </div>
            </nav>
        </div>
    );
}

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
