import React, { useState, useEffect } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { Typography, AppBar, Tabs, Tab, Box, Container, Card } from '@material-ui/core';

import check from 'src/assets/images/icons/check.svg';

import background from 'src/assets/images/topo-como-alugar-anage.webp';
import buy from 'src/assets/images/comprar-imovel-joinville.webp';
import financing from 'src/assets/images/financiamento-imovel-anage.webp';
import imgBanner from 'src/assets/images/duvidas-sobre-como-comprar-imovel.png';
import imgBannerMobile from 'src/assets/images/duvidas-sobre-como-comprar-imovel-mobile.png';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]); 

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {}
}));

function HowToRent() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    let pageTitle = 'Como Alugar';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="rental-calculator how-modality">
                <div className="page-header" style={{ backgroundImage: 'url(' + background + ')' }}>
                    <div className="description">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <h1 className="title title-1">
                                        O passo a passo para alugar seu imóvel em Joinville com a Anagê
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="buy-info">
                        <div className="block-info">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="description">
                                            <p className="text text-1">
                                            Alugar um imóvel com a Anagê é prático, rápido e fácil! Temos consultores especializados, imóveis nos melhores bairros e várias opções de garantias locatícias. Veja abaixo o passo a passo resumido
                                            </p>
                                            <a href="#modalidade-de-fianca" className="btn btn-1">
                                                Modalidades de Fiança
                                            </a>
                                        </div>
                                        <div className="steps">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="item">
                                                        <div className="step">1</div>
                                                        <h4 className="text text-1">
                                                            Visite nosso site, pesquise e encontre os imóveis de seu interesse;
                                                        </h4>
                                                    </div>
                                                    <div className="item">
                                                        <div className="step">2</div>
                                                        <h4 className="text text-1">
                                                            Agende visitas e escolha o imóvel ideal para você;
                                                        </h4>
                                                    </div>
                                                    <div className="item">
                                                        <div className="step">3</div>
                                                        <h4 className="text text-1">
                                                            Envie os documentos necessários para locação:
                                                        </h4>
                                                    </div>
                                                    <div className="item">
                                                        <div className="step">4</div>
                                                        <h4 className="text text-1">
                                                            Escolha a garantia locatícia e aguarde a análise de crédito;
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="item">
                                                        <div className="step">5</div>
                                                        <h4 className="text text-1">
                                                            Assine o contrato de locação e a vistoria inicial;
                                                        </h4>
                                                    </div>
                                                    <div className="item">
                                                        <div className="step">6</div>
                                                        <h4 className="text text-1">
                                                            Solicite a ligação de energia junto à Celesc;
                                                        </h4>
                                                    </div>
                                                    <div className="item">
                                                        <div className="step">7</div>
                                                        <h4 className="text text-1">
                                                            Verifique as regras e programe sua mudança;
                                                        </h4>
                                                    </div>
                                                    <div className="item">
                                                        <div className="step">8</div>
                                                        <h4 className="text text-1">
                                                            Retire as chaves e seja muito feliz em seu novo lar!
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simple-banner d-none">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="item">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4 className="title title-3">
                                                        Na Anagê você pode fazer todo esse <span>processo sem sair de casa</span>
                                                    </h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <a href="http://alugueldigitalanage.com.br" target="_blank" className="btn btn-8 btn-lg">alugueldigitalanage.com.br</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-info box-gray" id="modalidade-de-fianca">
                            <div className="container">
                                <div className="box">
                                    <div className="block-title">
                                        <h3 className="title title-2">
                                            Modalidades de Fiança
                                        </h3>
                                        <p className="text text-1">
                                            Deseja alugar imóvel sem complicação Joinville e região? Conte com a Anagê! Aqui você pode alugar imóvel sem fiador, com cartão de crédito ou com garantias locatícias tradicionais.
                                        </p>
                                    </div>
                                    <div className="block-tabs">
                                        <AppBar position="static" color="default">
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="fullWidth"
                                                aria-label="full width tabs example"
                                            >
                                                <Tab label="Seguro-Fiança" {...a11yProps(0)} className="btn btn-2" />
                                                <Tab label="Título de Capitalização" {...a11yProps(1)} />
                                                <Tab label="Loft" {...a11yProps(2)} />
                                                <Tab label="Fiador" {...a11yProps(3)} />
                                            </Tabs>
                                        </AppBar>
                                        <Box
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={value}
                                            onChangeIndex={handleChangeIndex} 
                                        >
                                            <TabPanel value={value} index={0} dir={theme.direction}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="info first">
                                                            <h3 className="title title-6">
                                                                Seguro-Fiança
                                                            </h3>
                                                            <p className="text text-1">
                                                                Nessa modalidade, o fiador é substituído por uma seguradora, que ficará responsável pelos encargos do locatário na falta do cumprimento das obrigações. O seguro é parcelado em 12 vezes no boleto do aluguel, com renovação automática anualmente.
                                                            </p>
                                                            <p className="text text-1">
                                                                Se você optar por essa modalidade para alugar um imóvel, a cobertura do seguro pode incluir também encargos de aluguéis como IPTU e condomínio, bem como danos ao imóvel.
                                                            </p>
                                                            <a href="#pre-cadastro" className="btn btn-3">Enviar Documentos</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info">
                                                            <h4 className="title title-6">
                                                                Documentação Exigida
                                                            </h4>
                                                            <div className="steps">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovação de renda de pelo menos 3 vezes o valor do aluguel;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Registrado: Carteira de Trabalho e 3 últimos holerites;
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Autônomo: Extrato bancário dos últimos 3 meses;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Cópia do RG e CPF;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovante de residência.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={value} index={1} dir={theme.direction}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="info first">
                                                            <h3 className="title title-6">
                                                                Título de Capitalização
                                                            </h3>
                                                            <p className="text text-1">
                                                                É a substituição do fiador com um depósito de 10 a 15 vezes o valor do aluguel mais encargos locatícios, junto a uma seguradora. No fim do contrato de locação, se não houver nenhuma pendência, o locatário recebe 100% do seu pagamento em parcela única, corrigida pela TR. 
                                                            </p>
                                                            <a href="#pre-cadastro" className="btn btn-3">Enviar Documentos</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info">
                                                            <h4 className="title title-6">
                                                                Documentação Exigida
                                                            </h4>
                                                            <div className="steps">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovação de renda de pelo menos 3 vezes o valor do aluguel;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Registrado: Carteira de Trabalho e 3 últimos holerites;
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Autônomo: Extrato bancário dos últimos 3 meses;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Cópia do RG e CPF;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovante de residência.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={value} index={2} dir={theme.direction}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="info first">
                                                            <h3 className="title title-6">
                                                                Loft
                                                            </h3>
                                                            <p className="text text-1">
                                                                Com a Loft, o seu cartão de crédito faz papel de fiador do contrato de locação. A Loft ficará responsável pelos encargos do locatário na falta do cumprimento das obrigações. Sem burocracia nas análises de crédito, para imóveis residenciais, todo o trâmite é efetuado online, gerando mais comodidade e agilidade ao processo.
                                                            </p>
                                                            <a href="#pre-cadastro" className="btn btn-3">Enviar Documentos</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info">
                                                            <h4 className="title title-6">
                                                                Documentação Exigida
                                                            </h4>
                                                            <div className="steps">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovação de renda de pelo menos 3 vezes o valor do aluguel;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Registrado: Carteira de Trabalho e 3 últimos holerites;
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                RG e CPF;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovação de crédito com limite superior a 4 vezes o valor do aluguel, por meio da fatura do cartão de crédito
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={value} index={3} dir={theme.direction}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="info first">
                                                            <h3 className="title title-6">
                                                                Fiador
                                                            </h3>
                                                            <p className="text text-1">
                                                                Outra parte envolvida no contrato de aluguel, que será responsável solidariamente por todas as obrigações do contrato de locação.
                                                            </p>
                                                            <a href="#pre-cadastro" className="btn btn-3">Enviar Documentos</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="info">
                                                            <h4 className="title title-6">
                                                                Documentação Exigida
                                                            </h4>
                                                            <div className="steps">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Carteira de Identidade e CPF (do casal, se houver cônjuge);
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Certidão de casamento;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovante de residência (conta de água ou luz);
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovante de rendimento igual ou superior a 3 vezes o valor do aluguel com holerite;
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Carteira de trabalho ou declaração de Imposto de Renda;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Comprovação de um imóvel quitado com matrícula em seu nome;
                                                                            </p>
                                                                        </div>
                                                                        <div className="step">
                                                                            <img alt="" src={check} className="icon"></img>
                                                                            <p className="text text-1">
                                                                                Caso o fiador seja casado, o cônjuge obrigatoriamente precisa estar anuente ao contrato.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <p><strong>Atenção:</strong> nenhuma das partes envolvidas poderá ter qualquer restrição em seu nome.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-info" id="pre-cadastro">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="box">
                                            <div className="block-title">
                                                <h3 className="title title-2">
                                                    Faça o pré-cadastro de locação
                                                </h3>
                                            </div>
                                            <form className="form" action="#" method="post">
                                                <h4 className="title title-8">
                                                    Dados Pessoais
                                                </h4>
                                                <div className="form-group">
                                                    <label htmlFor="modality-name" className="label-control">Nome</label>
                                                    <input type="text" className="form-control" id="modality-name" name="name" placeholder="Digite seu nome" />
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="modality-email" className="label-control">E-mail</label>
                                                            <input type="text" className="form-control" id="modality-email" name="email" placeholder="exemplo@email.com.br" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="modality-phone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                            <input type="text" className="form-control" id="modality-phone" name="phone" placeholder="(00) 00000-00000" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4 className="title title-8">
                                                    Documentos
                                                </h4>
                                                <div className="form-group">
                                                    <label htmlFor="modalitySelect" className="label-control">Modalidade de Fiança</label>
                                                    <select className="form-control custom-select" id="modalitySelect">
                                                        <option>Seguro-Fiança</option>
                                                        <option>Titulo de Capitalização</option>
                                                        <option>Credpago</option>
                                                        <option>Fiador</option>
                                                    </select>
                                                </div>
                                                <p className="text text-1">
                                                    A modalidade de fiança escolhida exige os seguintes documentos:
                                                </p>
                                                <p className="text text-1 list">
                                                    <span></span>RG e CPF;
                                                </p>
                                                <p className="text text-1 list">
                                                    <span></span>Comprovação de crédito com limite superior a 4 vezes o valor do aluguel, por meio da fatura do cartão de crédito.
                                                </p>
                                                <div className="custom-control custom-checkbox small-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="privacyPolicy" />
                                                    <label className="custom-control-label" htmlFor="privacyPolicy">Ao fornecer meus dados eu concordo em receber comunicações de acordo com a <RouterLink to="/politica-de-privacidade">Política de Privacidade.</RouterLink></label>
                                                </div>
                                                <button type="submit" className="btn btn-1">Enviar</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="item hidden-mobile" style={{ backgroundImage: 'url(' + imgBanner + ')' }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="block-info">
                                                    <div className="block">
                                                        <h3 className="title title-1">
                                                            Dúvidas sobre alugar imóveis em Joinville e Região?
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="block-info center">
                                                    <div className="block">
                                                        <p className="text text-1">
                                                            Acesse a nossa página de Dúvidas Frequentes!
                                                        </p>
                                                        <RouterLink to="/perguntas-frequentes" className="btn btn-5">
                                                            Dúvidas Frequentes
                                                        </RouterLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item show-mobile">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="block-info">
                                                    <div className="block">
                                                        <h3 className="title title-1">
                                                            Dúvidas sobre alugar imóveis em Joinville e Região?
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="block-info center"  style={{ backgroundImage: 'url(' + imgBannerMobile + ')' }}>
                                                    <div className="block">
                                                        <p className="text text-1">
                                                            Acesse a nossa página de Dúvidas Frequentes!
                                                        </p>
                                                        <RouterLink to="/perguntas-frequentes" className="btn btn-5">
                                                            Dúvidas Frequentes
                                                        </RouterLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <Newsletter />
        </Page>
    );
}

export default HowToRent;
