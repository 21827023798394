import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import Page from 'src/components/Page';
import PostsCategories from 'src/views/pages/ShowCase/DetailsCategory/PostsCategories';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import axios from 'src/utils/apiWP';

function DetailsCategory({ match }) {
    const dispatch = useDispatch();
    const [postCategories, setPostsCategories] = useState([]);
    const [filters, setFilters] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoriesSlug, setCategoriesSlug] = useState([]);
    const [paginator, setPaginator] = useState({
        currentPage: 1,
        perPage: 10,
        totalPages: 0,
        totalRecords: 0,
    });

    let path = match.params.slug;
    let entityId = categoriesSlug.length > 0 && categoriesSlug[0].id;
    let categoryName = categoriesSlug.length > 0 && categoriesSlug[0].name;

    const fecthCategoriesBySlug = () => {
        let requestId = uuidv4();

        dispatch(addLoadingGlobal(requestId));
        axios.get(`https://blog.anageimoveis.com.br/wp-json/wp/v2/categories?slug=${path}`).then((response) => {
            if (response.request.status === 200) {
                let posts = response.data;

                setCategoriesSlug(posts);
            }
        }).catch((error) => {
            console.log(error);
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    }

    const fetchCategoryPosts = () => {
        let requestId = uuidv4();

        dispatch(addLoadingGlobal(requestId));
        axios.get(`https://blog.anageimoveis.com.br/wp-json/wp/v2/posts?_embed&page=${paginator.currentPage}&per_page=5&categories=${entityId}`).then((response) => {
            if (response.request.status === 200) {
                let posts = response.data;
                setPaginator({
                    ...paginator,
                    totalPages: parseInt(response.headers['x-wp-totalpages']),
                    totalRecords: parseInt(response.headers['x-wp-total']),
                });
                setPostsCategories(posts);
            }
        }).catch((error) => {
            console.log(error);
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    };

    const fetchCategories = async () => {
        let requestId = uuidv4();

        dispatch(addLoadingGlobal(requestId));
        axios.get('https://blog.anageimoveis.com.br/wp-json/wp/v2/categories?hide_empty=true&per_page=100').then((response) => {
            if (response.request.status === 200) {
                setCategories(response.data);
            }
        }).catch((error) => {
            console.log(error);
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    };

    const filterPosts = async () => {
        let requestId = uuidv4();

        if (filters === '') {
            fetchCategoryPosts();
            return;
        }

        dispatch(addLoadingGlobal(requestId));
        axios.get(`https://blog.anageimoveis.com.br/wp-json/wp/v2/posts?_embed&page=${paginator.currentPage}&categories=${entityId}&search=${filters}`).then((response) => {
            if (response.request.status === 200) {
                let posts = response.data;
                setPaginator({
                    ...paginator,
                    totalPages: parseInt(response.headers['x-wp-totalpages']),
                    totalRecords: parseInt(response.headers['x-wp-total']),
                });
                setPostsCategories(posts);
            };
        }).catch((error) => {
            console.log(error);
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    };

    const handleFilterChange = (entities) => {
        setFilters(entities);
        setPaginator({
            ...paginator,
            currentPage: 1,
            perPage: 10,
            totalPages: paginator.totalPages,
            totalRecords: paginator.total
        });
    };

    useEffect(() => {
        if (filters !== '') {
            filterPosts();
            return;
        };

        fecthCategoriesBySlug();
        fetchCategoryPosts();
        fetchCategories();
    }, [path, entityId, paginator.currentPage, filters]);

    const setPages = (indexPage) => {
        setPaginator({
            ...paginator,
            currentPage: indexPage,
            perPage: 10,
            totalPages: paginator.totalPages,
            totalRecords: paginator.total
        });
    };

    return (
        <Page
            title={categoryName}
        >
            <PostsCategories
                onFilter={handleFilterChange}
                postCategories={postCategories}
                categories={categories}
                paginator={paginator}
                setPages={setPages}
                pageNeighbours={1}
                categoryName={categoryName}
            />
            <Newsletter />
        </Page>
    );
}

export default DetailsCategory;
