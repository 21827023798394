import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import like from 'src/assets/images/icons/like.svg';
import area from 'src/assets/images/icons/area-do-cliente.svg';
import close from 'src/assets/images/icons/close.svg';
import logo from 'src/assets/images/logo-anage-calculadora.svg';

import FavoriteModal from 'src/components/AnageImoveis/FavoriteModal.js';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

    export default function TemporaryDrawer() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        right: false,
    });

    const handleDrawerClose = () => {
        setOpen(false);
      };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Button onClick={handleDrawerClose} className="btn-close">
                <img alt="" src={close} className="icon"></img>
            </Button>
            <div className="hidden-mobile">
                <List>
                    <ListItem>
                        Imóveis
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/como-comprar">Como Comprar</RouterLink>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/como-alugar">Como Alugar</RouterLink>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/anuncie-seu-imovel">Anunciar Imóvel</RouterLink>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/calculadora">Calculadora de Aluguel</RouterLink>
                    </ListItem>
                </List>
                <List>
                    <ListItem>
                        Institucional
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/institucional">Sobre Nós</RouterLink>
                    </ListItem>
                    <ListItem>
                        <Link href="https://anageimoveis.solides.jobs/" target="_blank">Trabalhe Conosco</Link>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/joinville">Joinville</RouterLink>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/contato">Contato</RouterLink>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/blog">Blog</RouterLink>
                    </ListItem>
                </List>
            </div>
            <div className="show-mobile">
                <img alt="" src={logo} className="img-logo"></img>
                <List>
                    <ListItem>
                        Imóveis
                    </ListItem>
                    <ListItem>
                        <div className="form-row">
                            <div className="col-6">
                                <RouterLink to="/como-comprar">Como Comprar</RouterLink>
                            </div>
                            <div className="col-6">
                                <RouterLink to="/como-alugar">Como Alugar</RouterLink>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/anuncie-seu-imovel">Anunciar Imóvel</RouterLink>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/calculadora">Calculadora de Aluguel</RouterLink>
                    </ListItem>
                </List>
                <List>
                    <ListItem>
                        Institucional
                    </ListItem>
                    <ListItem>
                        <div className="form-row">
                            <div className="col-6">
                                <RouterLink to="/institucional">Sobre Nós</RouterLink>
                            </div>
                            <div className="col-6">
                                <Link href="https://anageimoveis.solides.jobs/" target="_blank">Trabalhe Conosco</Link>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem>
                        <RouterLink to="/joinville">Joinville</RouterLink>
                    </ListItem>
                    <ListItem>
                        <div className="form-row">
                            <div className="col-6">
                                <RouterLink to="/contato">Contato</RouterLink>
                            </div>
                            <div className="col-6">
                                <RouterLink to="/blog">Blog</RouterLink>
                            </div>
                        </div>
                    </ListItem>
                </List>
                <List>
                    <ListItem>
                        <RouterLink
                            to="/area-do-cliente"
                            className="btn-black"
                        >
                            <img alt="" src={area} className="icon"></img>
                            Área do Cliente
                        </RouterLink>
                    </ListItem>
                    <ListItem>
                        <RouterLink
                            to="/imoveis-favoritos"
                            className="btn-black"
                        >
                            <img alt="" src={like} className="icon icon-like"></img>
                            Meus Favoritos
                        </RouterLink>
                        <FavoriteModal />
                    </ListItem>
                </List>
            </div>
        </div>
    );

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <span className="bar bar-1"></span>
                        <span className="bar bar-2"></span>
                        <span className="bar bar-3"></span>
                        <span className="bar bar-4"></span>
                        <span className="bar bar-5"></span>
                        <span className="bar bar-6"></span>
                        <span className="bar bar-7"></span>
                        <span className="bar bar-8"></span>
                        <span className="bar bar-9"></span>
                    </Button>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} className="menu-drawer">
                        <div className="filter"></div>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
