import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import slugify from 'slugify';

import ManagerApi from 'src/services/managerApi';

import List from './List';
import Detail from './Detail';
import PageNotFound from 'src/views/pages/PageNotFound';

export default function Index({ match, ...rest }) {

    const path = match.params[0].substr(1).toLowerCase();
    const params = (path.split('/'));

    const queryParams = new URLSearchParams(window.location.search);

    const routerApi = new ManagerApi('/router');

    const generalInformation = useSelector((state) => state.interface.generalInformation);
    
    const dispatch = useDispatch();
    const [component, setComponent] = useState(false);

    const fetchRoute = async () => {

        if (!path) {
            return;
        }

        let redirectPaths = {
            'quero-alugar/imoveis/locacao' : '/alugar/joinville',
            'quero-alugar/imoveis/apartamento-locacao' : '/alugar/apartamento/joinville',
            'quero-alugar/imoveis/casa-comercial-locacao' : '/alugar/casa-comercial/joinville',
            'quero-alugar/imoveis/casa-residencial-locacao' : '/alugar/casa-residencial/joinville',
            'quero-alugar/imoveis/galpao-locacao' : '/alugar/galpao/joinville',
            'quero-alugar/imoveis/garagem-locacao' : '/alugar/garagem/joinville',
            'quero-alugar/imoveis/loja-locacao' : '/alugar/loja/joinville',
            'quero-alugar/imoveis/sala-locacao' : '/alugar/sala/joinville',
            'quero-alugar/imoveis/terreno-locacao' : '/alugar/tereno/joinville',
        }
        if(redirectPaths[path]) {
            setComponent(<Redirect to={{
                pathname: redirectPaths[path],
                state: {
                    status: 301
                }
            }} />);
        }

        let notFound = true;
        let filters = { url: path };
        let redirect = false;

        if(['quero-alugar'].indexOf(params[0])>=0 ) {
            if(queryParams.get('ref')) {
                filters = {code: queryParams.get('ref')};
                redirect = true;
            }
        }
        
        const requestId = uuidv4();
        dispatch(addLoadingGlobal(requestId));
        let router = await routerApi.getList(filters);
        dispatch(removeLoadingGlobal(requestId));

        if(router.data.content) {
            if(router.data.content.type === 'suggestion') {
                let suggestion = router.data.content.content;

                
                if(suggestion.neighbourhoods && suggestion.neighbourhoods.length) {
                    suggestion.neighbourhood = suggestion.neighbourhoods.split(',').map( s => s.trim());
                }
                
                let filters = {...suggestion}
                delete filters.id;
                delete filters.name;
                delete filters.slug;
                delete filters.buttonName;
                delete filters.visible;

                notFound = false;
                setComponent(<List filters={filters} suggestion={suggestion} />);
            }
            else if(router.data.content.type === 'realestate') {

                if("/"+router.data.content.content.slug != window.location.pathname) {
                    redirect = true;
                }

                if(redirect) {
                    notFound = false;
                    setComponent(<Redirect to={{
                        pathname: "/"+router.data.content.content.slug,
                        state: {
                            status: 301
                        }
                    }} />);
                }
                else {
                    notFound = false;
                    setComponent(<Detail realEstate={router.data.content.content} />);
                }
            }
        }
        else if(['comprar','alugar','lancamentos'].indexOf(params[0])>=0) {

            let filters = {};
            let validateParent = false;
            filters.status = params[0];

            filters.code = queryParams.get('code');
            filters.building = queryParams.get('building');

            if(params[0] === 'lancamentos') {
                filters.status = 'comprar';
                filters.launch = 1;
            }
            
            validateParent = (filters.status === 'comprar' || filters.status === 'lancamentos');

            if(params.length > 1) {
                for(let i=1; i<params.length; i++) {
                    
                    generalInformation.categories[params[0]].map( category => {
                        if( (!validateParent || (validateParent && !category.parent_id)) && params[i] == category.slug) {
                            filters.category = category.id;
                        }
                    });

                    generalInformation.locations[params[0]].map( location => {
                        if(params[i] == slugify(location.city.toLowerCase())) {
                            filters.city = location.city;
                        }

                        if(filters.city && filters.city == location.city) {
                            let newNeighbourhood = [];
                            location.neighbourhood.map( neighbourhood => {
                                let compare = slugify(neighbourhood.toLowerCase().replaceAll(' ',''));
                                params[i].split('-').map( param => {
                                    if(param == compare) {
                                        newNeighbourhood.push(neighbourhood);
                                    }
                                })
                            })
                            filters.neighbourhood = newNeighbourhood;
                        }

                    });
                }

            }

            notFound = false;
            setComponent(<List filters={filters} />);
        }

        if(notFound) {
            setComponent(<PageNotFound />)
        }

    };

    useEffect(() => {
        fetchRoute();
    }, [match]);

    if (component === false) {
        return (<></>);
    }

    return component;

}
