import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal, addMessage, addBrokerInformation } from 'src/actions';
import { ErrorMessage, Formik, Form as FormikForm, Field, Label } from 'formik';
import * as Yup from 'yup';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';

import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { Link as RouterLink } from 'react-router-dom';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import ClassContact from 'src/models/BrokerModels';
import SearchFilters from 'src/components/AnageImoveis/SearchFilters';
import SearchResults from 'src/components/AnageImoveis/SearchResults';
import background from 'src/assets/images/anage-imobiliaria-joinville.webp';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';
import { getWhatsappLink } from 'src/utils/functions';
import moment from 'moment';

let classContact = new ClassContact();
const defaultFormShape = classContact.getObjects;

const useStyles = makeStyles(() => ({
    root: {}
}));

function Broker({ history, match }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { trackEvent } = useAnalytics();
    const [broker, setBroker] = useState(null);
    
    const [realEstateFilters, setRealEstateFilters] = useState({status: 'comprar', city: 'Joinville'});
    const [realEstateSort, setRealEstateSort] = useState(null);
    const [realEstates, setRealEstates] = useState(null);
    const [paginator, setPaginator] = useState({
        pageCurrent: 1,
        totalItemCount: null
    });

    const brokerApi = new ManagerApi('/realestate/realestateagent');
    const realestateApi = new ManagerApi('/realestate/realestate');
    const contactApi = new ManagerApi('/contact/contact');
    const { slug } = match.params;

    const handlePaginate = (page) => {
        window.scrollTo(0, 0);
        let pageContent = document.getElementById('result-content');
        let mainHeader = document.getElementById('main-header')
        if(pageContent) {
            window.scrollTo(0, pageContent.offsetTop - mainHeader.offsetHeight - 30);
        }
        setPaginator({
            ...paginator,
            pageCurrent: page
        })
    };

    const handleFilter = (values) => {
        setRealEstateFilters({ ...values });
    }

    const handleSort = (sort) => {
        let sortType = {
            priceAsc: {
                priceRental: "ASC",
                priceSale: "ASC",
            },
            priceDesc: {
                priceRental: "DESC",
                priceSale: "DESC",
            },
            areaAsc: { area: "ASC" },
            areaDesc: { area: "DESC" },
        }

        if(sortType[sort]) {
            setRealEstateSort(sortType[sort]);
        }
        else {
            setRealEstateSort(null);
        }
    }
    
    const fetchBroker = () => {
        const requestId = uuidv4();
        if (!slug) {
          return;
        }
        dispatch(addLoadingGlobal(requestId));
        brokerApi.getList({slug: slug, active: 1}).then((response) => {
            if (response.data.success && response.data.content[0]) {
                setBroker(response.data.content[0]);
                dispatch(addBrokerInformation({
                    broker: response.data.content[0],
                    datetime: moment()
                }))
            }
            else {
                history.push("/");
            }
        }).catch(() => {
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    };

    const fetchRealestate = () => {
        if(!broker) {
            return;
        }
        const requestId = uuidv4();
        dispatch(addLoadingGlobal(requestId));
        realestateApi.getList({
            ...realEstateFilters,
            agent: broker.id,
            paginator: {
                pageCurrent: paginator.pageCurrent,
                pageItemsPerPage: 100,
                sortOrder: realEstateSort
            }
        }).then((response) => {
            if (response.data.success) {
                setRealEstates(response.data.content);
                setPaginator(response.data.paginator);
            }
        }).catch(() => {
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    }

    useEffect(() => {
        fetchBroker();
    }, [slug]);

    useEffect(() => {
        fetchRealestate();
    }, [broker, realEstateFilters, realEstateSort, paginator.pageCurrent]);
  
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
    });

    if(!broker) {
        return (<></>);
    }
    
    let pageTitle = broker.name;
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="broker-section">
                <div className="broker-header" style={{ backgroundImage: 'url(' + background + ')' }}></div>
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="description">
                                            <div className="image" style={{ backgroundImage: 'url(' + broker.photo + ')' }}></div>
                                            <h2 className="title title-1">
                                                {broker.name}
                                            </h2>
                                            <div className="info">
                                                <p className="text text-1">
                                                    CRECI: {broker.creci}
                                                 </p>
                                                <p className="text text-1">
                                                  {broker.description}
                                                </p>
                                            </div>
                                            {broker.cellphone && (
                                                <a href={getWhatsappLink(broker.cellphone)} className="btn btn-whats"
                                                    onClick={() => { trackEvent('Clique no botão', 'Whatsapp Corretor', broker.cellphone) }}
                                                >
                                                    <img alt="" src={whatsapp} className="icon"></img>
                                                    Conversar pelo Whatsapp
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="block-form">
                                            <Formik
                                                initialValues={defaultFormShape}
                                                enableReinitialize
                                                validationSchema={validationSchema}
                                                validateOnChange={false}
                                                validateOnBlur={false}
                                                onSubmit={(values , {resetForm, setStatus}) => {
                                                    let data = new FormData();
                                                    data = values;

                                                    data.subject = "Corretor - Fale comigo - " + broker.name;
                                                    data.broker = broker.id;

                                                    dispatch(addLoadingGlobal());
                                                    contactApi.post(data).then(response => {
                                                        dispatch(removeLoadingGlobal());
                                                        if (response.data.success == true) {
                                                            dispatch(addMessage('Mensagem enviada com sucesso.', 'success'));
                                                            setStatus({
                                                                sent: true,
                                                                msg: "Mensagem enviada com sucesso."
                                                            });
                                                            window.location.replace('/mensagem-enviada-com-sucesso');
                                                        } else {
                                                            setStatus({
                                                                sent: false,
                                                                msg: "Houve um problema! Tente mais tarde."
                                                            })
                                                        }
                                                    });
                                                }}
                                            >
                                                { ({ handleBlur, handleChange, status }) => (
                                                <FormikForm className="form">
                                                    <h4 className="title title-3">Fale Comigo</h4>
                                                    <div className="form-group">
                                                        <label htmlFor="brokerFormName" className="label-control">Nome</label>
                                                        <Field type="text" className="form-control" id="brokerFormName" onBlur={handleBlur} onChange={handleChange} name="name" placeholder="Digite seu nome" />
                                                        <ErrorMessage component="span" name="name" className="error-message" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="brokerFormEmail" className="label-control">E-mail</label>
                                                        <Field type="email" className="form-control" id="brokerFormEmail" onBlur={handleBlur} onChange={handleChange} name="email" placeholder="exemplo@email.com.br" />
                                                        <ErrorMessage component="span" name="email" className="error-message" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="brokerFormPhone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                        <Field type="text" className="form-control" id="brokerFormPhone" onBlur={handleBlur} onChange={handleChange} name="phone" placeholder="(00) 00000-00000" />
                                                        <ErrorMessage component="span" name="phone" className="error-message" />
                                                    </div>
                                                    <div className="custom-control custom-checkbox small-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="privacyPolicy" name="acceptPolicy" onChange={handleChange} />
                                                        <label className="custom-control-label" htmlFor="privacyPolicy">Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>, nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o contato comercial da Anagê Imóveis</label>
                                                    </div>
                                                    <button type="submit" name="button" className="btn btn-1">Enviar</button>
                                                </FormikForm>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="result-content" className="result-content">

                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block-title">
                                    <h3 className="title title-2">
                                        Conheça meus imóveis
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-md-12">
                                <SearchFilters filters={ realEstateFilters } onFilter={ handleFilter } onSort={ handleSort } />
                                <div className="container">
                                    <SearchResults
                                        realEstates={realEstates}
                                        filters={ realEstateFilters }
                                        paginator={ paginator }
                                        onFilter={ null }
                                        onSort={ handleSort }
                                        onPaginate={ handlePaginate }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="property-list">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="card card-broker-help">
                                                <div className="info-banner" style={{ backgroundImage: 'url(' + background + ')' }}>
                                                    <div className="block">
                                                        <h4 className="title title-3">
                                                            Não encontrou o que procurava?
                                                        </h4>
                                                        <p className="text text-1">
                                                            Procurando imóvel para comprar ou alugar em Joinville? Conte com a Anagê para encontrar o imóvel ideal para você!
                                                        </p>
                                                        <RouterLink
                                                            to="/encontrar-meu-imovel"
                                                            className="btn btn-1"
                                                        >
                                                            Quero Ajuda
                                                        </RouterLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default Broker;
