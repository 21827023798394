import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import search from 'src/assets/images/icons/search.svg';
import tag from 'src/assets/images/icons/label.svg';
import placeholder from 'src/assets/images/placeholder.webp';

export default function PostContent({ post, subTitle, categories, onFilter, relatedPosts }) {
    let [valueInput, setValueInput] = useState('');

    const createMarkup = (content) => {
        return {
            __html: content
        };
    };

    const handleChange = (e) => {
        const { value } = e.target;

        setValueInput(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        onFilter(valueInput);
    };

    const getThumbnail = (post) => {
        var elem = document.createElement('div');
        elem.style.display = 'none';
        elem.innerHTML = post.content.rendered;
        if(elem.querySelector('img')) {
            return elem.querySelector('img').src;
        }
        return '';
    }
    
    return (
        <div className="blog-section internal">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="top-blog">
                            <div className="block-title">
                                <RouterLink to="/blog" className="title title-1 d-block">Blog Anagê Imóveis</RouterLink>
                            </div>
                            <form onSubmit={handleSubmit} className="form-search">
                                <div className="form-group">
                                    <img alt="" src={search} className="icon"></img>
                                    <input
                                        type="text"
                                        name="search"
                                        value={valueInput}
                                        className="form-control"
                                        placeholder="Faça uma pesquisa"
                                        onChange={handleChange}
                                    />
                                    <button type="submit" className="btn btn-1 btn-search">Buscar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-1">
                        {post.id > 0 && 
                            <div className="content-post">
                                <h1 className="title title-1">{post.title.rendered}</h1>
                                <p className="text text-2" dangerouslySetInnerHTML={createMarkup(post.excerpt.rendered)} />
                                <p className="text text-2" dangerouslySetInnerHTML={createMarkup(post.content.rendered)} />
                                <div className="content-category">
                                    <img alt="" src={tag} className="icon"></img>
                                    <span className="text text-5">
                                        {subTitle.length > 0 && subTitle.map(subTitle => {
                                            return (
                                                subTitle.name
                                            )
                                        })}
                                    </span>
                                    <span className="text text-5">{ moment(post.date).format('DD/MM/YY') }</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {relatedPosts && relatedPosts.length > 0 && (
                <div className="blog-block">
                    <div className="container">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="title-block">
                                                <h2 className="title title-2">Posts relacionados</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {relatedPosts.map(post => (
                                            <div className="col-md-6">
                                                <RouterLink to={`/blog/${post.slug}`} className="blog-item" style={{ backgroundImage: 'url(' + getThumbnail(post) + ')' }}>
                                                    <div>
                                                        <h3 className="title">{post.title.rendered}</h3>
                                                        <span>Ler mais</span>
                                                    </div>
                                                </RouterLink>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            
        </div>
    )
}
