import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import { Link as RouterLink } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { getWhatsappLink } from 'src/utils/functions';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';
import background from 'src/assets/images/duvidas-sobre-alugar-ou-comprar-imoveis.webp';

export default function CustomizedAccordions() {

    const { trackEvent } = useAnalytics();

    const [expanded, setExpanded] = React.useState('panel1');
    const generalInformation = useSelector((state) => state.interface.generalInformation);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const dispatch = useDispatch();
    const [faqCategories, setFaqCategories] = useState([]);
    const [faqs, setFaqs] = useState([]);
    

    const faqsApi = new ManagerApi('/faq/faq');
    const faqCategoriesApi = new ManagerApi('/faq/faqcategory');
    

    const fetchFaqCategories = async () => {
        const requestID = uuidv4();

        dispatch(addLoadingGlobal(requestID));

        let filters = {
            paginator: {
                pageItemsPerPage: 100,
                sortOrder: {
                    position: 'ASC'
                }
            }
        }
        faqCategoriesApi.getList(filters).then((response) => {
            if (response.data.success) {
                setFaqCategories(response.data.content);
            }
        }).catch((error) => {
        }).then(() => {
            dispatch(removeLoadingGlobal(requestID));
        });
    }

    useEffect(() => {
        fetchFaqCategories();
    }, []);

    const fetchFaqs = async () => {
        const requestID = uuidv4();

        dispatch(addLoadingGlobal(requestID));

        let filters = {
            paginator: {
                pageItemsPerPage: 100,
                sortOrder: {
                    position: 'ASC'
                }
            }
        }
        faqsApi.getList(filters).then((response) => {
            if (response.data.success) {
                setFaqs(response.data.content);
            }
        }).catch((error) => {
        }).then(() => {
            dispatch(removeLoadingGlobal(requestID));
        });
    }

    useEffect(() => {
        fetchFaqs();
    }, []);

    const getFaqList = () => {
        return faqCategories.length > 0 && faqCategories.map((faqCategory, i) => {
            return(
                <div className="accordion-group">
                    <h2 className="title title-2" key={faqCategory.i}>
                        {faqCategory.name}
                    </h2>
                    {faqs.length > 0 && faqs.map((faq, i) => {
                        if(faq.category && faq.category.id == faqCategory.id) {
                            return (
                                <Accordion square expanded={expanded === faq.id} onChange={handleChange(faq.id)}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography className="title title-5">{faq.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className="text text-1" >
                                            <div dangerouslySetInnerHTML={{__html: faq.answer}}></div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                    })}
                </div>
            )
        })
    };

    return (
        <div className="faq-section">
            <div className="page-header large" style={{ backgroundImage: 'url(' + background + ')' }}>
                <div className="description">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 offset-md-1">
                                <h1 className="title title-1">
                                    Tire aqui suas dúvidas sobre venda e locação de imóveis.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="accordion-block">
                                {getFaqList()}
                            </div>
                            <div className="info">
                                <h3 className="title title-2">
                                    Ainda está com dúvidas? Entre em contato conosco!   
                                </h3>
                                <RouterLink
                                    to="/contato"
                                    className="btn btn-1"
                                >Entrar em Contato</RouterLink>
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-1">
                            <div className="shops-block">
                                <h3 className="title title-2">Fale Conosco</h3>
                                {generalInformation.stores.length > 0 && generalInformation.stores.map((store, i) => {
                                    return (
                                        <div className="item">
                                            <div className="description">
                                                <h4 className="title title-3">
                                                    {store.name}
                                                </h4>
                                                <a href={"tel:"+store.phone} className="tel title title-4">
                                                    {store.phone}
                                                </a>
                                                <a href={getWhatsappLink(store.whatsapp)} target="_blank" className="tel title title-4"
                                                    onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', store.phone) }}
                                                >
                                                    {store.whatsapp}
                                                    <img alt="" src={whatsapp} className="icon"></img>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}