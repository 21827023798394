import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';

import ClusterMarker from '../ClusterMarker';

import mapStyles from './mapStyles.json';

import MapWrapper from './MapWrapper';

export default function GoogleMap({ realEstatesLocations, filters, bounds, onChangeBounds, onChangeFilters }) {

  const generalInformation = useSelector((state) => state.interface.generalInformation);

  const mapSettings = {
    styles: mapStyles,
    maxZoom: 18,
    minZoom: 12
  };
  
  const [zoom, setZoom] = useState(13);
  const [clusters, setClusters] = useState([]);
  const [clusterActived, setClusterActived] = useState(0);
  const mapRef = useRef();

  function handleGetClusterPoints(cluster_id, latitude, longitude) {
    const { points } = clusters.find(cluster=>cluster.id === cluster_id);

    if(points.length > 20) {
      setZoom(zoom + 2);
      mapRef.current.setZoom(zoom + 2);
      mapRef.current.panTo({ lat: latitude, lng: longitude });
      return;
    }

    const realEstatesIds = points.map(point=>point.id);
    onChangeFilters({...filters, ids: realEstatesIds});

    setClusterActived(cluster_id);
  }

  function getClusters() {
    const currentClusters = supercluster(realEstatesLocations, {
      minZoom: 0,
      maxZoom: 20,
      radius: 75,
    });

    return currentClusters({
      zoom,
      bounds
    });
  };

  function createClusters() {
    const currentClusters = getClusters().map(({ wx, wy, numPoints, points }) => ({
      lat: wy,
      lng: wx,
      numPoints,
      id: `${numPoints}_${points[0].id}`,
      points,
    }));

    setClusters(currentClusters);
  };

  function handleMapChange({ zoom, bounds }) {
    if(filters.ids) {
      onChangeFilters({...filters, ids: null});
    }

    setZoom(zoom);
    onChangeBounds(bounds);
    setClusterActived(0);
  };

  useEffect(() => {
    Object.keys(bounds).length && createClusters();
  }, [bounds]);

  return (
    <MapWrapper>
      <GoogleMapReact
        defaultCenter={{ lat: parseFloat(realEstatesLocations[0].lat), lng: parseFloat(realEstatesLocations[0].lng) }}
        defaultZoom={13}
        options={mapSettings}
        onChange={handleMapChange}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: generalInformation.googleMapsKey }}
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
      >
        {clusters.map(item => {   
          return (       
            <ClusterMarker
              key={item.id}
              lat={item.lat}
              lng={item.lng}
              points={item.points}
              onClick={() => handleGetClusterPoints(item.id, item.lat, item.lng)}
              active={item.id === clusterActived}
            />
          )
        })}
      </GoogleMapReact>
    </MapWrapper>
  );
}
