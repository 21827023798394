class ClassSimulate {

    constructor() {
        this.getObjects = this.getObjects();
    }

    getObjects = () => {
        return {
            clientType: '',
            name: '',
            email: '',
            phone: '',
            dateOfBirth: '',
            familiarIncome: '',
            documents: '',
            status: '',
            type: '',
            price: '',
            state: '',
            city: '',
            workingTime: false,
            subsidy: false,
            dependents: false,
            acceptPolicy: false
        }
    }
}

export default ClassSimulate;