import styled from 'styled-components';

const MarkerCounter = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  margin-left: -15px;
  text-align: center;
  font-size: 14px;
  color: ${props => props.active ? '#ffffff' : '#1a1818'};
  background-color: ${props => props.active ? '#17a2b8' : '#ffd300'};
  border-radius: 50%;
  border: none;

  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }
  
  &:active, &:focus {
    outline:none;
  }
`;

export default MarkerCounter;
