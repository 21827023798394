import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import client from 'src/assets/images/icons/client-area.svg';
import found from 'src/assets/images/icons/fundo-de-reservas-taxas.svg';
import contest from 'src/assets/images/icons/contestacoes-vistorias.svg';
import warning from 'src/assets/images/aviso-desocupacao.webp';
import maintenance from 'src/assets/images/manutencao-imovel.webp';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {}
}));

function TenantArea() {
    const classes = useStyles();

    let pageTitle = 'Sou Inquilino';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            to: '/area-do-cliente',
            title: 'Área do Cliente'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="client-content tenant-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-title">
                                <h2 className="title title-10">
                                    Sou Inquilino
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="box simple">
                                <div className="info-centered">
                                    <h3 className="title title-11">
                                        <img alt="" src={client} className="icon"></img>
                                        Área do Inquilino
                                    </h3>
                                    <p className="text text-1">
                                        Área de inquilinos, <span>para acesso a sua 2ª via de boletos.</span>
                                    </p>
                                    <a href="https://anage.bitrix24.site/chamados_loc/" className="btn btn-1" target="_blank">
                                        Acessar
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="box large">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img alt="" src={warning} className="image img-fluid"></img>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="info-centered">
                                            <h3 className="title title-11">
                                                Aviso de Desocupação
                                            </h3>
                                            <p className="title title-8">
                                                Comunique sua desocupação clicando no botão abaixo. Você receberá um e-mail confirmando sua solicitação e orientações de como proceder: O pleno distrato do contrato ou rescisão contratual, ocorrerá no momento em que for constatado que o imóvel se apresenta nas mesmas condições descritas na vistoria de entrada. Também é imprescindível que estejam quitados os aluguéis e encargos da locação.
                                            </p>
                                            <a href="#" className="btn btn-1">
                                                Em breve
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="box large">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img alt="" src={maintenance} className="image img-fluid"></img>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="info-centered">
                                            <h3 className="title title-11">
                                                Manutenção
                                            </h3>
                                            <p className="title title-8">
                                                Registre seu pedido de manutenção, solicite orçamento e análise de problemas encontrados em seu imóvel durante seu contrato de locação clicando no botão abaixo. Você vai acessar nosso sistema interativo e poderá solicitar seu pedido de reparos junto à nossa central de atendimento.
                                            </p>
                                            <RouterLink to="/area-do-cliente/sou-inquilino/manutencao" className="btn btn-1">
                                                Acessar
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="box box-form">
                                <div className="info-centered">
                                    <h3 className="title title-11">
                                        <img alt="" src={found} className="icon"></img>
                                        Fundo de Reservas e Taxas
                                    </h3>
                                    <p className="title title-8">
                                        Para receber o reembolso do fundo de reservas e outras taxas extraordinárias, preencha seus dados abaixo e anexe o boleto comprovante de pagamento. Você será reembolsado no próximo mês.
                                    </p>
                                    <div className="block-form">
                                        <form className="form" action="#" method="post">
                                            <div className="form-group">
                                                <label htmlFor="foundForm-name" className="label-control">Nome</label>
                                                <input type="text" className="form-control" id="foundForm-name" name="name" placeholder="Digite seu nome" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="foundForm-email" className="label-control">E-mail</label>
                                                <input type="text" className="form-control" id="foundForm-email" name="email" placeholder="exemplo@email.com.br" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="foundForm-phone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                <input type="text" className="form-control" id="foundForm-phone" name="phone" placeholder="(00) 00000-00000" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="foundForm-code" className="label-control">Código do contrato</label>
                                                <input type="text" className="form-control" id="foundForm-code" name="code" placeholder="0000.0000-00" />
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="foundForm-month" className="label-control">Mês do condomínio</label>
                                                        <input type="text" className="form-control" id="foundForm-month" name="month" placeholder="0000.0000-00" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="foundForm-vality" className="label-control">Vencimento</label>
                                                        <input type="text" className="form-control" id="foundForm-vality" name="vality" placeholder="0000.0000-00" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="upload-file">
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="inputGroupFile01" />
                                                        <label className="custom-file-label" htmlFor="inputGroupFile01">
                                                            Anexe o comprovante de pagamento
                                                            <span>Arraste e solte o arquivo ou toque para selecionar</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <a href="#" className="link">Remover anexos</a>
                                            </div>
                                            <button className="btn btn-1">Enviar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-6">
                            <div className="box box-form">
                                <div className="info-centered">
                                    <h3 className="title title-11">
                                        <img alt="" src={contest} className="icon"></img>
                                        Contestações e Vistorias
                                    </h3>
                                    <p className="title title-8">
                                        Eventuais divergências ou complementação de detalhes omitidos, devem ser apresentados no prazo improrrogável de 48 horas úteis a partir da entrega de chaves. Preencha os campos abaixo e anexe seu arquivo.
                                    </p>
                                    <div className="block-form">
                                        <form className="form" action="#" method="post">
                                            <div className="form-group">
                                                <label htmlFor="contestForm-name" className="label-control">Nome</label>
                                                <input type="text" className="form-control" id="contestForm-name" name="name" placeholder="Digite seu nome" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contestForm-email" className="label-control">E-mail</label>
                                                <input type="text" className="form-control" id="contestForm-email" name="email" placeholder="exemplo@email.com.br" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contestForm-phone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                <input type="text" className="form-control" id="contestForm-phone" name="phone" placeholder="(00) 00000-00000" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contestForm-code" className="label-control">Código do contrato</label>
                                                <input type="text" className="form-control" id="contestForm-code" name="code" placeholder="0000.0000-00" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contestForm-message" className="label-control">Observações</label>
                                                <textarea className="form-control" id="contestForm-message" rows="5" placeholder="Digite a mensagem"></textarea>
                                            </div>
                                            <button className="btn btn-1">Enviar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default TenantArea;
