import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, AppBar, Tabs, Tab, Box, Container, Card } from '@material-ui/core';
import CardProperty from 'src/components/AnageImoveis/CardProperty';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ManagerApi from 'src/services/managerApi';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));
  
export default function FullWidthTabs() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const realEstateApi = new ManagerApi('/realestate');

    const [realEstatesRent, setRealEstatesRent] = useState(null);
    const [realEstatesBuy, setRealEstatesBuy] = useState(null);
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const fetchRealEstate = (filter, callbackSet) => {
        realEstateApi.getList({
            ...filter,
            paginator: {
                pageItemsPerPage: 9,
                sortOrder: {
                  createdAt: 'DESC'
                }
            }
        }).then((response) => {
            if (response.data.success) {
                callbackSet(response.data.content);
            }
        }).catch((error) => {
        }).then(() => {
        });
    }

    useEffect(() => {
        fetchRealEstate({status: 'alugar'} ,setRealEstatesRent);
        fetchRealEstate({status: 'comprar', featured: 1} ,setRealEstatesBuy);
    }, []);
  
    return (
        <div className="featured-properties">
            <div className="container">
                <div className="block">
                    <Box>
                        <h2 className="title title-2">
                            Imóveis em destaque
                        </h2>
                        <p className="text text-1">
                            Encontre aqui o seu imóvel para ALUGAR e COMPRAR em Joinville
                        </p>
                    </Box>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Imóveis para Alugar" {...a11yProps(0)} />
                            <Tab label="Imóveis para Comprar" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <Box
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex} 
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            {realEstatesRent && (
                                <Swiper
                                    slidesPerGroup={3}
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    navigation
                                    pagination
                                    breakpoints={{
                                        576: {
                                            "slidesPerView": 2,
                                            "slidesPerGroup": 2
                                        },
                                        992: {
                                            "slidesPerView": 3,
                                            "slidesPerGroup": 3
                                        },
                                    }}
                                >
                                    {realEstatesRent.map( (realEstate) => (
                                        <SwiperSlide>
                                            <CardProperty realEstate={realEstate} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            {realEstatesBuy && (
                                <Swiper
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    navigation
                                    pagination
                                    breakpoints={{
                                        576: {
                                            "slidesPerView": 2,
                                        },
                                        992: {
                                            "slidesPerView": 3,
                                        },
                                    }}
                                >
                                    {realEstatesBuy.map( (realEstate) => (
                                        <SwiperSlide>
                                            <CardProperty realEstate={realEstate} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            )}
                        </TabPanel>
                    </Box>
                </div>
            </div>
        </div>
    );
}
