import React from 'react';

import MarkerCounter from './MarkerCounter';

export default function ClusterMarker({ points, onClick, active }) {
  return (
    <MarkerCounter active={active} onClick={onClick}>
      {points.length}
    </MarkerCounter>
  );
}
