class ClassAdvertiseProperty {

    constructor() {
        this.getObjects = this.getObjects();
    }

    getObjects = () => {
        return {
            name: '',
            email: '',
            phone: '',
            status: '',
            city: '',
            neighbourhood: '',
            type: '',
            acceptPolicy: false,
        }
    }
}

export default ClassAdvertiseProperty;