import React, {
        useEffect,
        useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { addInformation, addFavorite, addBrokerInformation } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import moment from 'moment';

function Auth({ children }) {
        const dispatch = useDispatch();
        const [isLoading, setLoading] = useState(true);

        useEffect(() => {
                const initAuth = async () => {

                        let response;

                        let information = {};
    
                        const generalInformationApi = new ManagerApi('/company');
                        response = await generalInformationApi.getList();
                        if (response.data.success) {
                                information = response.data.content;
                        }
                        await dispatch(addInformation(information));

                        let favoriteList = localStorage.getItem('favoriteList');
                        if(favoriteList) {
                                favoriteList = favoriteList.split(',');
                                await favoriteList.map( item => dispatch(addFavorite(item)) );
                        }

                        let brokerInfo = localStorage.getItem('brokerInformation');
                        if(brokerInfo) {
                                brokerInfo = JSON.parse(brokerInfo);
                                if(brokerInfo.datetime) {
                                        let timeBroker = moment(brokerInfo.datetime);
                                        let timeNow = moment();
                                        let diffDays = diffDays = timeNow.diff(timeBroker, 'days'); 
                                        if(diffDays >= 2) {
                                                brokerInfo = null;      
                                        }
                                        else {
                                                brokerInfo.datetime = timeNow;
                                        }
                                }
                                await dispatch(addBrokerInformation(brokerInfo));
                        }

                        setLoading(false);
                };

                initAuth();
        }, [dispatch]);

        if (isLoading) {
                return <SplashScreen />;
        }

        return children;
}

Auth.propTypes = {
        children: PropTypes.any
};

export default Auth;
