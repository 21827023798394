import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassContact from 'src/models/ContactModels';
import { getWhatsappLink } from 'src/utils/functions';
import HowRentJoinville from 'src/assets/images/comprar-alugar-imovel-em-joinville.webp';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';
import background from 'src/assets/images/topo-fale-com-anage-imoveis.webp';
let classContact = new ClassContact();

const defaultFormShape = classContact.getObjects;

export default function ContactContent({}) {
    const dispatch = useDispatch();
    const { trackEvent } = useAnalytics();
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const contactApi = new ManagerApi('/contact/contact');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
        department: Yup.string().required('Campo obrigatório.'),
        message: Yup.string().required('Campo obrigatório.'),
        bestTime: Yup.string().required('Campo obrigatório.'),
    });
    
    const styleSuccess = {
        color:'green',
        textAlign:'center'
    }

    const styleDeny = {
        color:'red',
        textAlign:'center'
    }

    return (
        <div className="contact-section">
            <div className="page-header large" style={{ backgroundImage: 'url(' + background + ')' }}>
                <div className="description">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 offset-md-1">
                                <h1 className="title title-1">
                                    Entre em contato com a Anagê Imóveis, sua imobiliária em Joinville
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="form-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className="title title-2">Quero falar com a Anagê</h2>
                                <p className="text text-1">
                                    Precisa falar com a gente? Preencha o formulário de contato com a Anagê com seus dados e envie sua mensagem.
                                </p>
                                <Formik
                                    initialValues={defaultFormShape}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values , {resetForm, setStatus}) => {
                                        let data = new FormData();
                                        data = values;

                                        if(!values.acceptPolicy) {
                                            dispatch(addMessage('Para prosseguir, você precisa aceitar os termos de envio.', 'error'));
                                            return;
                                        }

                                        dispatch(addLoadingGlobal());
                                        contactApi.post(data).then(response => {
                                            dispatch(removeLoadingGlobal());

                                            if (response.data.success == true) {
                                                dispatch(addMessage('Registro realizado com sucesso.', 'success'));
                                                resetForm();
                                                trackEvent('Envio de formulário', 'Contato', '')
                                            }
                                            else {
                                                dispatch(addMessage('Não foi possível enviar o contato, por favor tente novamente mais tarde.', 'error'));
                                            }
                                        });
                                    }}
                                >
                                    { ({ handleBlur, handleChange, setFieldValue, status, values }) => (
                                    <FormikForm className="form" id="form-anage-contato">
                                        <div className="form-group">
                                            <label htmlFor="contactName" className="label-control">Nome</label>
                                            <Field type="text" className="form-control" id="contactName" onBlur={handleBlur} onChange={handleChange} name="name" placeholder="Digite seu nome" />
                                            <ErrorMessage component="span" name="name" className="error-message" />
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="contactEmail" className="label-control">E-mail</label>
                                                    <Field type="email" className="form-control" id="contactEmail" onBlur={handleBlur} onChange={handleChange} name="email" placeholder="exemplo@email.com.br" />
                                                    <ErrorMessage component="span" name="email" className="error-message" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="contactPhone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                    <Field type="text" className="form-control" id="contactPhone" onBlur={handleBlur} onChange={handleChange} name="phone" placeholder="(00) 00000-00000" />
                                                    <ErrorMessage component="span" name="phone" className="error-message" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="contactSubject" className="label-control">Assunto</label>
                                            <Field type="text" className="form-control" id="contactSubject" onBlur={handleBlur} onChange={handleChange} name="subject" placeholder="Informe o assunto" />
                                            <ErrorMessage component="span" name="subject" className="error-message" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="contactSector" className="label-control">Setor</label>
                                            <Field name="department" as="select" className="form-control custom-select" id="contactSector" onBlur={handleBlur} onChange={handleChange}>
                                                <option>Selecione</option>
                                                <option value="Quero alugar um imóvel">Quero alugar um imóvel</option>
                                                <option value="Quero comprar um imóvel">Quero comprar um imóvel</option>
                                                <option value="Quero deixar meu imóvel para alugar">Quero deixar meu imóvel para alugar</option>
                                                <option value="Ver vagas de trabalho disponíveis">Ver vagas de trabalho disponíveis</option>
                                            </Field>
                                            <ErrorMessage component="span" name="department" className="error-message" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="contactMessage" className="label-control">Mensagem</label>
                                            <Field className="form-control" as="textarea" id="contactMessage" name="message" placeholder="Mensagem" />
                                            <ErrorMessage component="span" name="message" className="error-message" />
                                        </div>
                                        <div className="form-group normal-group inline-group">
                                            <p className="text text-1">Melhor horário para falar:</p>
                                            <Field name="bestTime" as="select" className="form-control custom-select" id="contactHour">
                                                <option value="">Selecione</option>
                                                <option value="Manhã">Manhã</option>
                                                <option value="Almoço">Almoço</option>
                                                <option value="Tarde">Tarde</option>
                                                <option value="Noite">Noite</option>
                                            </Field>
                                            <ErrorMessage component="span" name="bestTime" className="error-message" />
                                        </div>
                                        <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('acceptPolicy',!values.acceptPolicy) } }>
                                            <input type="checkbox" className="custom-control-input" name="acceptPolicy" checked={values.acceptPolicy} />
                                            <label className="custom-control-label">Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>, nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o contato comercial da Anagê Imóveis</label>
                                        </div>
                                        <button type="submit" name="button" className="btn btn-1">Enviar</button>
                                    </FormikForm>
                                    )}
                                </Formik>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <h2 className="title title-2">A Anagê Imóveis</h2>
                                <p className="text text-1">
                                    Conheça a nossa história, diferenciais, serviços e saiba como nos tornamos a imobiliária referência em Joinville e Região.
                                </p>
                                <RouterLink to="/institucional" className="btn btn-3">Conheça Nossa História</RouterLink>
                                <a href="https://anageimoveis.solides.jobs/" target="_blank" className="btn btn-3">Trabalhe Conosco</a>
                                <RouterLink to="/anuncie-seu-imovel" className="btn btn-3">Anunciar Imóvel</RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shops">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="block-title">
                                    <h3 className="title title-2">
                                        Lojas Anagê
                                    </h3>
                                </div>
                                <div className="shops-list">
                                    <div className="row">
                                        {generalInformation.stores.length > 0 && generalInformation.stores.map((store, i) => {
                                            return (
                                                <div key={i} className="col-md-6">
                                                    <div className="card-shop">
                                                        <div className="image" style={{ backgroundImage: 'url(' + store.image + ')' }}></div>
                                                        <div className="description">
                                                            <h4 className="title title-3">
                                                                {store.name}
                                                            </h4>
                                                            <p className="text text-1">
                                                                {store.address}
                                                            </p>
                                                            <p className="text text-1">
                                                                <span>{store.email}</span>
                                                            </p>
                                                            <a href={"tel:"+store.phone} className="tel title title-4">
                                                                {store.phone}
                                                            </a>
                                                            <a href={getWhatsappLink(store.whatsapp)} target="_blank" className="tel title title-4"
                                                                onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', store.whatsapp) }}
                                                            >
                                                                {store.whatsapp}
                                                                <img alt="" src={whatsapp} className="icon"></img>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-city">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2 className="title title-2">
                                            Imobiliária em Joinville
                                        </h2>
                                        <p className="text text-1">
                                            A Anagê Imóveis é apaixonada por Joinville e por isso conhece tudo sobre a cidade. Conheça a nossa página especial com os melhores bairros para morar em Joinville, áreas de lazer e vantagens de morar aqui.   
                                        </p>
                                        <RouterLink to="/joinville" className="btn btn-1">Conhecer Joinville</RouterLink>
                                    </div>
                                    <div className="col-md-6">
                                        <img alt="" src={HowRentJoinville} className="image img-fluid"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
