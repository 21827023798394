import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

function GoogleTagManager() {
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const googleTagManagerId = generalInformation.googleTagManager;

    if (!googleTagManagerId) {
        return (<></>);
    }

    return (
        <Helmet>
            <script>
            {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${googleTagManagerId}');
            `}
            </script>
        </Helmet>
    );
}

function GoogleAnalytics() {
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const googleAnalyticsId = generalInformation.googleAnalytics;

    if (!googleAnalyticsId) {
        return (<></>);
    }

    return (
        <Helmet>
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
            />
            <script>
            {`
                window.dataLayer = window.dataLayer || [];
    
                function gtag() {
                    dataLayer.push(arguments);
                }
    
                gtag('js', new Date());
                gtag('config', '${googleAnalyticsId}', {send_page_view: false});
            `}
            </script>
        </Helmet>
    );
}

function FacebookPixel() {
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const facebookPixelId = generalInformation.facebookPixel;

    if (!facebookPixelId) {
        return (<></>);
    }
    
    return (
        <Helmet>
            <script>
            {
                `!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js%27);
                fbq('init', '${facebookPixelId}');
                fbq('track', 'PageView');
            `}
            </script>
        </Helmet>
    );
}

export function Analytics() {
    return (
        <>
            <GoogleAnalytics />
            <GoogleTagManager />
            <FacebookPixel />
        </>
    );
}
