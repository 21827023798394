import React, { useState, useEffect } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));
  
export default function InterestSearchBlock() {
    const classes = useStyles();
    const theme = useTheme();
    const generalInformation = useSelector((state) => state.interface.generalInformation);



    const [value, setValue] = React.useState(0);

  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className="searches-block">
            <div className="container">
                <Box>
                    <h2 className="title title-15">
                        Anagê Imóveis: <br/>
                        a sua imobiliária em Joinville
                    </h2>
                </Box>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Alugar" {...a11yProps(0)} />
                        <Tab label="Comprar" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <Box
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex} 
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <div className="words-block interest-block">
                            {generalInformation.suggestions['alugar'].length > 0 && generalInformation.suggestions['alugar'].map((category, i) => {
                                let url = category.slug;
                                let urlSplit = url.split('http');
                                if (urlSplit.length > 1) {
                                    if (category.image) {
                                        return (
                                            <a href={url} class="btn btn-arrow">
                                                <img alt={category.buttonName} src={category.image}></img>
                                                <span>{category.buttonName}</span>
                                            </a>
                                        )
                                    } else {
                                        return (
                                            <a href={url} class="btn btn-arrow">
                                                <span></span>
                                                <span>{category.buttonName}</span>
                                            </a>
                                        )
                                    }
                                } else {
                                    if (category.image) {
                                        return (
                                            <RouterLink to={category.slug} key={category.i} className="btn btn-arrow">
                                                <img alt={category.buttonName} src={category.image}></img>
                                                <span>{category.buttonName}</span>
                                            </RouterLink>
                                        )
                                    } else {
                                        return (
                                            <RouterLink to={category.slug} key={category.i} className="btn btn-arrow">
                                                <span></span>
                                                <span>{category.buttonName}</span>
                                            </RouterLink>
                                        )
                                    }
                                }
                            })}
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <div className="words-block interest-block">
                            {generalInformation.suggestions['comprar'].length > 0 && generalInformation.suggestions['comprar'].map((category, i) => {
                                let url = category.slug;
                                let urlSplit = url.split('http');
                                if (urlSplit.length > 1) {
                                    if (category.image) {
                                        return (
                                            <a href={url} class="btn btn-arrow">
                                                <img alt={category.buttonName} src={category.image}></img>
                                                <span>{category.buttonName}</span>
                                            </a>
                                        )
                                    } else {
                                        return (
                                            <a href={url} class="btn btn-arrow">
                                                <span></span>
                                                <span>{category.buttonName}</span>
                                            </a>
                                        )
                                    }
                                } else {
                                    if (category.image) {
                                        return (
                                            <RouterLink to={category.slug} key={category.i} className="btn btn-arrow">
                                                <img alt={category.buttonName} src={category.image}></img>
                                                <span>{category.buttonName}</span>
                                            </RouterLink>
                                        )
                                    } else {
                                        return (
                                            <RouterLink to={category.slug} key={category.i} className="btn btn-arrow">
                                                <span></span>
                                                <span>{category.buttonName}</span>
                                            </RouterLink>
                                        )
                                    }
                                }
                            })}
                        </div>
                    </TabPanel>
                </Box>
            </div>
        </div>
    );
}