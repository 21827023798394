import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { Link as RouterLink } from 'react-router-dom';
import millenials from 'src/assets/images/icons/icon-aluguel-fortalecimento.webp';
import trend from 'src/assets/images/icons/icon-aluguel-tendencia.webp';
import lifestyle from 'src/assets/images/icons/icon-aluguel-lifestyle.webp';
import appreciation from 'src/assets/images/icons/icon-aluguel-valorizacao.webp';
import reduce from 'src/assets/images/icons/icon-aluguel-reduza.webp';
import refresh from 'src/assets/images/icons/refresh.svg';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';

import background from 'src/assets/images/topo-como-comprar-anage.webp';
import imgBanner from 'src/assets/images/duvidas-sobre-como-comprar-imovel.png';
import imgBannerMobile from 'src/assets/images/duvidas-sobre-como-comprar-imovel-mobile.png';
import buy from 'src/assets/images/comprar-imovel-joinville.webp';
import buyMobile from 'src/assets/images/comprar-imovel-joinville-mobile.png';
import financing from 'src/assets/images/financiamento-imovel-anage.webp';
import consortium from 'src/assets/images/consorcio-imovel-anage.png';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]); 

const useStyles = makeStyles(() => ({
    root: {}
}));

function HowToBuy() {
    const classes = useStyles();

    let pageTitle = 'Como Comprar';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="rental-calculator how-modality">
                <div className="page-header" style={{ backgroundImage: 'url(' + background + ')' }}>
                    <div className="description">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <h2 className="title title-1">
                                        Conte com a Anagê Imóveis para comprar seu imóvel em Joinville!
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="buy-info">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="description">
                                        <p className="text text-1">
                                            A Anagê Imóveis te ajuda a realizar o sonho da casa própria ou do melhor investimento em imóveis. Aqui, você pode comprar seu imóvel com tranquilidade e segurança. Além da maior variedade de imóveis em Joinville e região, oferecemos orientação de especialistas no mercado imobiliário com orientação durante todo o processo. Venha fazer o melhor negócio! Veja abaixo o passo a passo resumido ou <a href="#opcoes-de-pagamento">clique para conhecer as opções de pagamento</a> do seu imóvel:
                                        </p>
                                    </div>
                                    <div className="steps">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <div className="step">1</div>
                                                    <h4 className="text text-1">
                                                        Visite nosso site, pesquise e encontre os imóveis de seu interesse;
                                                    </h4>
                                                </div>
                                                <div className="item">
                                                    <div className="step">2</div>
                                                    <h4 className="text text-1">
                                                        Agende visitas e escolha o imóvel ideal para você;
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <div className="step">3</div>
                                                    <h4 className="text text-1">
                                                        Faça uma proposta, garanta a reserva do imóvel e aguarde o aceite;
                                                    </h4>
                                                </div>
                                                <div className="item">
                                                    <div className="step">4</div>
                                                    <h4 className="text text-1">
                                                        Envie os documentos necessários para elaboração do contrato;
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="specific-info">
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img alt="" src={buyMobile} className="img-fluid image show-mobile"></img>
                                                <img alt="" src={buy} className="img-fluid image hidden-mobile"></img>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="info">
                                                    <h3 className="title title-6">
                                                        Para pagamento à vista de imóveis prontos
                                                    </h3>
                                                    <p className="text text-1">
                                                        Assine o contrato de compra e venda, realize o pagamento das taxas (ITBI e RFJ), aguarde e assine a escritura, pague os emolumentos e aguarde 30 dias que é o prazo para recebimento da matrícula atualizada.
                                                    </p>
                                                </div>
                                                <div className="info">
                                                    <h3 className="title title-6">
                                                        Para financiamento de imóveis prontos
                                                    </h3>
                                                    <p className="text text-1">
                                                        Aprove o seu crédito assine o contrato de compra e venda e dê sequência no seu contrato de financiamento. Assine o contrato de financiamento que será enviado para Registro, aguarde 30 dias e a entrega do contrato na agência onde será liberado o recurso na conta do vendedor.
                                                    </p>
                                                </div>
                                                <div className="info">
                                                    <h3 className="title title-6">
                                                        Para imóveis na planta
                                                    </h3>
                                                    <p className="text text-1">
                                                        Assine o contrato de compra e venda, realize o pagamento do sinal e aguarde o prazo de obra para quitação, com financiamento ou não.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-title" id="opcoes-de-pagamento">
                                    <h3 className="title title-2">
                                        Você pode escolher:
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buy-options financing-block">
                        <div className="container">
                            <div className="description">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img alt="" src={financing} className="img-fluid image"></img>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="info">
                                            <div className="block">
                                                <h4 className="title title-3">
                                                    Financiamento   
                                                </h4>
                                                <p className="text text-1">
                                                    Sonha em comprar um imóvel que caiba no seu orçamento? O financiamento tem como vantagem a rapidez no processo. Faça uma simulação de financiamento com a Anagê e conheça os detalhes sobre prazos e condições.
                                                </p>
                                                <RouterLink to="/financiamento" className="btn btn-1">
                                                    Simular Financiamento
                                                </RouterLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="how-it-works">
                                <div className="block-title">
                                    <h4 className="title title-3">
                                        Como funciona
                                    </h4>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-4">
                                        <div className="item">
                                            <div className="step">1</div>
                                            <p className="text text-1">
                                                Defina o valor do imóvel que será financiado
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <div className="step">2</div>
                                            <p className="text text-1">
                                                Separe em torno de 20% do valor do imóvel para dar como entrada
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <div className="step">3</div>
                                            <p className="text text-1">
                                                Avalie os juros, a correção monetária e o prazo para pagamento
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buy-options consortium-block">
                        <div className="container">
                            <div className="description">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img alt="" src={consortium} className="img-fluid image"></img>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="info">
                                            <div className="block">
                                                <h4 className="title title-3">
                                                    Consórcio
                                                </h4>
                                                <p className="text text-1">
                                                    Para comprar um imóvel em Joinville e região você pode optar por um consórcio. A vantagem da modalidade é um custo final menor do que o financiamento. Outras vantagens: prazo em até 180 meses, parcelas acessíveis e poder de compra. Em parceria com a Embracon, a Anagê facilita o consórcio do seu imóvel.
                                                </p>
                                                <a href="#como-funciona" className="btn btn-1">
                                                    Como Funciona
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="how-it-works" id="como-funciona">
                                <div className="block-title">
                                    <h4 className="title title-3">
                                        Como funciona
                                    </h4>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className="item">
                                            <h4 className="title title-4">
                                                <div className="step">1</div>
                                                Escolhermos o grupo para você
                                            </h4>
                                            <p className="text text-1">
                                                Ao aderir a um plano, os consorciados recebem um número de identificação (cota) e passam a fazer parte de um grupo. Procuramos sempre agregar pessoas com objetivos similares em cada grupo do consórcio.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="item">
                                            <h4 className="title title-4">
                                                <div className="step">2</div>
                                                Realizamos o sorteio
                                            </h4>
                                            <p className="text text-1">
                                                O sorteio acontece uma vez por mês, em assembleia. O contemplado tem direito ao valor da carta de crédito em sua totalidade, de acordo com o bem que selecionou. Os sorteios continuam acontecendo, até que todos os participantes do grupo tenham sido contemplados.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="item">
                                            <h4 className="title title-4">
                                                <div className="step">3</div>
                                                Você pode dar lances
                                            </h4>
                                            <p className="text text-1">
                                                O lance é uma boa dica para aumentar suas chances de ser contemplado no consórcio. Existem duas modalidades de lance: o livre ou o fixo, e uma delas pode acelerar o sonho da casa própria.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="item">
                                            <h4 className="title title-4">
                                                <div className="step">4</div>
                                                Contemplação
                                            </h4>
                                            <p className="text text-1">
                                                Ainda que não tenha dado lances, em um dos sorteios você será contemplado. E terá o direito de receber a sua carta de crédito do consórcio. O crédito será liberado após a entrega da documentação e da análise feita.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="item hidden-mobile" style={{ backgroundImage: 'url(' + imgBanner + ')' }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="block-info">
                                                    <div className="block">
                                                        <h3 className="title title-1">
                                                            Dúvidas sobre comprar imóveis em Joinville e Região?
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="block-info center">
                                                    <div className="block">
                                                        <p className="text text-1">
                                                            Acesse a nossa página de Dúvidas Frequentes!
                                                        </p>
                                                        <RouterLink to="/perguntas-frequentes" className="btn btn-5">
                                                            Dúvidas Frequentes
                                                        </RouterLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item show-mobile">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="block-info">
                                                    <div className="block">
                                                        <h3 className="title title-1">
                                                            Dúvidas sobre comprar imóveis em Joinville e Região?
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="block-info center" style={{ backgroundImage: 'url(' + imgBannerMobile + ')' }}>
                                                    <div className="block">
                                                        <p className="text text-1">
                                                            Acesse a nossa página de Dúvidas Frequentes!
                                                        </p>
                                                        <RouterLink to="/perguntas-frequentes" className="btn btn-5">
                                                            Dúvidas Frequentes
                                                        </RouterLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <Newsletter />
        </Page>
    );
}

export default HowToBuy;
