import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageClassWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Remove any existing page classes
    document.body.className = '';
    // Add the new page class based on the current path
    let path = location.pathname.substring(1); // Remove leading slash
    if (path.endsWith('/')) {
      path = path.slice(0, -1); // Remove trailing slash
    }
    if (path) {
      path = path.replace(/\//g, '-'); // Replace all slashes with hyphens
      document.body.classList.add(`page-${path}`);
    } else {
      document.body.classList.add('page-home');
    }
  }, [location]);

  return <>{children}</>;
};

export default PageClassWrapper;
