class ClassCalculate {

    constructor() {
        this.getObjects = this.getObjects();
    }

    getObjects = () => {
        return {
            name: '',
            email: '',
            phone: '',
            city: '',
            neighbourhood: '',
            category: '',
            privateArea: '',
            propertyAge: '',
            iptuValue: '',
            condominiumValue: '',
            interest: '',
            acceptPolicy: false,
            purpose: '', 
            bedrooms: '', 
            suites: '', 
            garage: '', 
            furniture: '', 
            available: '',
            version: '' 
        }
    }
}

export default ClassCalculate;