import React, {  } from 'react';
import { makeStyles } from '@material-ui/core';
import { useAnalytics } from 'src/hooks/useAnalytics';

import whatsapp from 'src/assets/images/icons/whatsapp.svg';
import { getWhatsappLink } from 'src/utils/functions';

const useStyles = makeStyles(() => ({
    root: {}
}));

export default function ShopsContent({ shops }) {
    const { trackEvent } = useAnalytics();

    return (
        <div className="shops">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-title">
                            <h3 className="title title-2">
                                Lojas Anagê Imóveis
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        {shops.length > 0 && shops.map((shop, i) => {
                            return (
                                <div key={shop.i} className="item">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img alt="" src={shop.image} className="img-fluid image"></img>
                                        </div>
                                        <div className="col-md-5 offset-md-1">
                                            <div className="info-centered">
                                                <div className="description">
                                                    <h4 className="title title-3">{shop.name}</h4>
                                                    <p className="text text-1">
                                                        {shop.address}
                                                    </p>
                                                    <a className="text text-1">
                                                        <span>{shop.email}</span>
                                                    </a>
                                                    <a href={"tel:"+shop.phone} className="tel title title-4">
                                                        {shop.phone}
                                                    </a>
                                                    <a href={getWhatsappLink(shop.whatsapp)} target="_blank" className="tel title title-4"
                                                        onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', shop.whatsapp) }}
                                                    >
                                                        {shop.whatsapp}
                                                        <img alt="" src={whatsapp} className="icon"></img>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* <div className="item">
                            <div className="row">
                                <div className="col-md-5">
                                    <img alt="" src={anageCentroVendas} className="img-fluid image"></img>
                                </div>
                                <div className="col-md-5 offset-md-1">
                                    <div className="info-centered">
                                        <div className="description">
                                            <h4 className="title title-3">Anagê Centro - Vendas</h4>
                                            <p className="text text-1">
                                                R. Max Colin, 285 - Centro - Joinville (SC) CEP 89201-215
                                            </p>
                                            <a className="text text-1">
                                                <span>anagecentro@anageimoveis.com.br</span>
                                            </a>
                                            <a href="#" className="tel title title-4">
                                                <small>47</small> 3025-3000
                                            </a>
                                            <a href="#" className="tel title title-4">
                                                <small>47</small> 9 9109-2525
                                                <img alt="" src={whatsapp} className="icon"></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row">
                                <div className="col-md-5">
                                    <img alt="" src={anageNorte} className="img-fluid image"></img>
                                </div>
                                <div className="col-md-5 offset-md-1">
                                    <div className="info-centered">
                                        <div className="description">
                                            <h4 className="title title-3">Anagê Norte - Venda e Locação</h4>
                                            <p className="text text-1">
                                                R. Blumenau, 3033 - Santo Antônio - Joinville (SC) CEP 89218-055
                                            </p>
                                            <a className="text text-1">
                                                <span>anagenorte@anageimoveis.com.br</span>
                                            </a>
                                            <a href="#" className="tel title title-4">
                                                <small>47</small> 3025-3001
                                            </a>
                                            <a href="#" className="tel title title-4">
                                                <small>47</small> 9 9146-7040
                                                <img alt="" src={whatsapp} className="icon"></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row">
                                <div className="col-md-5">
                                    <img alt="" src={anageLancamentos} className="img-fluid image"></img>
                                </div>
                                <div className="col-md-5 offset-md-1">
                                    <div className="info-centered">
                                        <div className="description">
                                            <h4 className="title title-3">Anagê Lançamentos - Vendas</h4>
                                            <p className="text text-1">
                                                R. Max Colin, 240 - Centro - Joinville (SC) CEP 89201-215
                                            </p>
                                            <a className="text text-1">
                                                <span>anagelancamentos@anageimoveis.com.br</span>
                                            </a>
                                            <a href="#" className="tel title title-4">
                                                <small>47</small> 3025-3005
                                            </a>
                                            <a href="#" className="tel title title-4">
                                                <small>47</small> 9 9968-0901
                                                <img alt="" src={whatsapp} className="icon"></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}