class ClassFindProperty {

    constructor() {
        this.getObjects = this.getObjects();
    }

    getObjects = () => {
        return {
            name: '',
            email: '',
            phone: '',
            status: 'Aluguel',
            city: '',
            category: '',
            priceRange: '',
            privateArea: '',
            bedrooms: '',
            parkingSpaces: '',
            comments: '',
            acceptPolicy: false
        }
    }
}

export default ClassFindProperty;