import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ErrorMessage, Formik, Form as FormikForm, Field, Label } from 'formik';
import * as Yup from 'yup';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassContact from 'src/models/SimulateModels';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { Link as RouterLink } from 'react-router-dom';

let classContact = new ClassContact();

const defaultFormShape = classContact.getObjects;

const useStyles = makeStyles(() => ({
    root: {}
}));

function FindProperty() {
    const classes = useStyles();
    const { trackEvent } = useAnalytics();
    const dispatch = useDispatch();
    const contactApi = new ManagerApi('/contact/financingsimulator');

    const validationSchema = Yup.object().shape({
        clientType: Yup.string().required('Campo obrigatório.'),
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
        dateOfBirth: Yup.string().required('Campo obrigatório.'),
        familiarIncome: Yup.string().required('Campo obrigatório.'),
        documents: Yup.string().required('Campo obrigatório.'),
        status: Yup.string().required('Campo obrigatório.'),
        type: Yup.string().required('Campo obrigatório.'),
        price: Yup.string().required('Campo obrigatório.'),
        state: Yup.string().required('Campo obrigatório.'),
        city: Yup.string().required('Campo obrigatório.'),
        acceptPolicy: Yup.string().required('Campo obrigatório.'),
    });
    
    const styleSuccess = {
        color:'green',
        textAlign:'center'
    }

    const styleDeny = {
        color:'red',
        textAlign:'center'
    }

    let pageTitle = 'Simular Financiamento';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="simulate-financing">
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-10 col-md-7">
                                        <div className="block-title">
                                            <h2 className="title title-1">
                                                Informe qual o objetivo do financiamento e o valor desejado
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="action">
                                            <RouterLink to="/como-comprar" className="btn btn-3">
                                                <span className="icon"></span>
                                                Voltar
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="block-form">
                                            <Formik
                                                initialValues={defaultFormShape}
                                                enableReinitialize
                                                validationSchema={validationSchema}
                                                validateOnChange={false}
                                                validateOnBlur={false}
                                                onSubmit={(values , {resetForm, setStatus}) => {
                                                    let data = new FormData();
                                                    data = values;
            
                                                    if(!values.acceptPolicy) {
                                                        dispatch(addMessage('Para prosseguir, você precisa aceitar os termos de envio.', 'error'));
                                                        return;
                                                    }
            
                                                    dispatch(addLoadingGlobal());
                                                    contactApi.post(data).then(response => {
                                                        dispatch(removeLoadingGlobal());
            
                                                        if (response.data.success == true) {
                                                            dispatch(addMessage('Registro realizado com sucesso.', 'success'));
                                                            trackEvent('Envio de formulário', 'Financiamento', '')
                                                            resetForm();
                                                        }
                                                        else {
                                                            dispatch(addMessage('Não foi possível enviar o contato, por favor tente novamente mais tarde.', 'error'));
                                                        }
                                                    });
                                                }}
                                            >
                                                { ({ handleBlur, handleChange, setFieldValue, status, values }) => (
                                                <FormikForm className="form">
                                                    <div className="block-check">
                                                        <p className="title title-8">
                                                            Este financiamento é para:
                                                        </p>
                                                        <div className="group">
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="radioType1" name="clientType" className="custom-control-input" onChange={handleChange} value="Pessoa Física" />
                                                                <label className="custom-control-label" htmlFor="radioType1">Pessoa Física</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="radioType2" name="clientType" className="custom-control-input" onChange={handleChange} value="Pessoa Jurídica" />
                                                                <label className="custom-control-label" htmlFor="radioType2">Pessoa Jurídica</label>
                                                            </div>
                                                            <ErrorMessage component="span" name="clientType" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="simulateFormName" className="label-control">Nome</label>
                                                        <Field type="text" className="form-control" id="simulateFormName" onBlur={handleBlur} onChange={handleChange} name="name" placeholder="Digite seu nome" />
                                                        <ErrorMessage component="span" name="name" className="error-message" />
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="simulateFormEmail" className="label-control">E-mail</label>
                                                                <Field type="email" className="form-control" id="simulateFormEmail" onBlur={handleBlur} onChange={handleChange} name="email" placeholder="exemplo@email.com.br" />
                                                                <ErrorMessage component="span" name="email" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="simulateFormPhone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                                <Field type="text" className="form-control" id="simulateFormPhone" onBlur={handleBlur} onChange={handleChange} name="phone" placeholder="(00) 00000-00000" />
                                                                <ErrorMessage component="span" name="phone" className="error-message" />
                                                            </div>  
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="simulateFormDateBirth" className="label-control">Data de Nascimento</label>
                                                                <Field type="text" className="form-control" id="simulateFormDateBirth" onBlur={handleBlur} onChange={handleChange} name="dateOfBirth" placeholder="00/00/0000" />
                                                                <ErrorMessage component="span" name="dateOfBirth" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="simulateFormIncome" className="label-control">Renda bruta familiar (R$)</label>
                                                                <Field type="text" className="form-control" id="simulateFormIncome" onBlur={handleBlur} onChange={handleChange} name="familiarIncome" placeholder="Ex: R$ 2500" />
                                                                <ErrorMessage component="span" name="familiarIncome" className="error-message" />
                                                            </div>  
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="simulateFormDocument" className="label-control">CPF</label>
                                                        <Field type="text" className="form-control" id="simulateFormDocument" onBlur={handleBlur} onChange={handleChange} name="documents" placeholder="000.000.000-00" />
                                                        <ErrorMessage component="span" name="documents" className="error-message" />
                                                    </div>
                                                    <div className="block-check">
                                                        <p className="title title-8">
                                                            Qual tipo de financiamento você deseja?
                                                        </p>
                                                        <div className="group">
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="radioFinancing1" name="status" className="custom-control-input" onChange={handleChange} value="Imóvel Novo" />
                                                                <label className="custom-control-label" htmlFor="radioFinancing1">Imóvel novo</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="radioFinancing2" name="status" className="custom-control-input" onChange={handleChange} value="Imóvel usado" />
                                                                <label className="custom-control-label" htmlFor="radioFinancing2">Imóvel usado</label>
                                                            </div>
                                                            <ErrorMessage component="span" name="status" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="SimulateFormType" className="label-control">Tipo do Imóvel</label>
                                                                <Field type="text" className="form-control" id="SimulateFormType" onBlur={handleBlur} onChange={handleChange} name="type" placeholder="Casa, Apartamento, Sala comercial"/>
                                                                <ErrorMessage component="span" name="type" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="SimulateFormPrice" className="label-control">Valor aproximado do imóvel (R$)</label>
                                                                <Field type="text" className="form-control" id="SimulateFormPrice" onBlur={handleBlur} onChange={handleChange} name="price" placeholder="Ex: R$ 350.000"/>
                                                                <ErrorMessage component="span" name="price" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="SimulateFormState" className="label-control">Estado (UF)</label>
                                                                <Field type="text" className="form-control" id="SimulateFormState" onBlur={handleBlur} onChange={handleChange} name="state" placeholder="Estado do imóvel"/>
                                                                <ErrorMessage component="span" name="state" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="SimulateFormCity" className="label-control">Cidade</label>
                                                                <Field type="text" className="form-control" id="SimulateFormCity" onBlur={handleBlur} onChange={handleChange} name="city" placeholder="Cidade do imóvel"/>
                                                                <ErrorMessage component="span" name="city" className="error-message" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('workingTime',!values.workingTime) } }>
                                                        <input type="checkbox" className="custom-control-input" name="workingTime" checked={values.workingTime} />
                                                        <label className="custom-control-label" htmlFor="check1">
                                                            Possuo 3 anos de trabalho sob regime do FGTS, somando todos os períodos trabalhados.    
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('subsidy',!values.subsidy) } }>
                                                        <input type="checkbox" className="custom-control-input" name="subsidy" checked={values.subsidy} />
                                                        <label className="custom-control-label" htmlFor="check2">
                                                            Já fui beneficiado, ou o imóvel objeto do financiamento, com subsídio concedido pelo FGTS/UNIÃO.
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('dependents',!values.dependents) } }>
                                                        <input type="checkbox" className="custom-control-input" name="dependents" checked={values.dependents} />
                                                        <label className="custom-control-label" htmlFor="check3">
                                                            Mais de um comprador dependente.    
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('acceptPolicy',!values.acceptPolicy) } }>
                                                        <input type="checkbox" className="custom-control-input" name="acceptPolicy" checked={values.acceptPolicy} />
                                                        <label className="custom-control-label">Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>, nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o contato comercial da Anagê Imóveis</label>
                                                    </div>
                                                    <button type="submit" className="btn btn-1">Solicitar Simulação</button>
                                                </FormikForm>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <Newsletter />
        </Page>
    );
}

export default FindProperty;
