import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import slugify from 'slugify';

import SearchFilters from 'src/components/AnageImoveis/SearchFilters';

const defaultFormShape = {
    status: "alugar"
};
const validationSchema = Yup.object().shape({
    // name: Yup.string().required('Campo obrigatório.'),
    // email: Yup.string().required('Campo obrigatório.'),
    // phone: Yup.string().required('Campo obrigatório.'),
    // department: Yup.string().required('Campo obrigatório.'),
    // message: Yup.string().required('Campo obrigatório.'),
    // bestTime: Yup.string().required('Campo obrigatório.'),
});

export default function MainBanner({ banner }) {

    const dispatch = useDispatch();
    const history = useHistory();

    const generalInformation = useSelector((state) => state.interface.generalInformation);

    const handleFilter = (values) => {

        let statusLabel = '';
        if(values.status == 'alugar') {
            statusLabel = 'alugar';
        }
        else {
            statusLabel = 'comprar';
            if(values.launch) {
                statusLabel = 'lancamentos';
            }
        }

        let url = [];
        let params = [];
        url.push(statusLabel.toLowerCase());

        if(values.category) {
            generalInformation.categories[values.status.toLowerCase()].map(category => {
                if(category.id == values.category) {
                    url.push(category.slug);
                }
            })
        }
        if(values.city) {
            url.push(slugify(values.city.toLowerCase()));
        }
        if(values.neighbourhood) {
            let neighbourhoods = [...values.neighbourhood];
            neighbourhoods.sort();
            neighbourhoods.map((element, index) => {
                element = element.toLowerCase().replaceAll(' ','');
                element = slugify(element);
                neighbourhoods[index] = element;
            });
            if(neighbourhoods.length) {
                url.push(neighbourhoods.join('-'));
            }
        }
        if(values.code) {
            params = '?code='+values.code
        }
        else if(values.name) {
            params = '?building='+encodeURI(values.name)
        }

        history.push("/" + url.join('/')+params);

    }

    return (
        <div className="banner-section" style={{ backgroundImage: 'url(' + banner + ')' }}>
            <div className="container">
                <div className="block-centered">
                    <h1 className="title title-1"><span>Anagê Imóveis:</span> a sua imobiliária em Joinville</h1>
                    <div className="block-search">
                        <div className="blur"></div>
                        <SearchFilters filters={ {status: 'alugar'} } onFilter={ handleFilter } compact={true} showMapButton={false}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
