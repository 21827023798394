import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import client from 'src/assets/images/icons/client-area.svg';

const useStyles = makeStyles(() => ({
    root: {}
}));

function OwnerArea() {
    const classes = useStyles();

    let pageTitle = 'Sou Proprietário';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            to: '/area-do-cliente',
            title: 'Área do Cliente'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="client-content owner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-title">
                                <h2 className="title title-10">
                                    Sou Proprietário
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="box simple">
                                <div className="info-centered">
                                    <h3 className="title title-11">
                                        <img alt="" src={client} className="icon"></img>
                                        Área do Proprietário
                                    </h3>
                                    <p className="text text-1">
                                        Área de Proprietários, para acesso a sua <span>2ª via de Prestação de contas e Demonstrativo de Imposto de Renda.</span>
                                    </p>
                                    <a href="https://anage.bitrix24.site/chamados_prop/" className="btn btn-1" target="_blank">
                                        Acessar
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default OwnerArea;
