import React from 'react';

import logo from 'src/assets/images/logo-creci-vertical.png';

function Logo(props) {
        return (
                <img
                        alt="Logo"
                        src={logo}
                        {...props}
                />
        );
}

export default Logo;
