import React, { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import fastForward from 'src/assets/images/icons/fast-forward.svg';
import handshake from 'src/assets/images/icons/handshake.svg';
import laptop from 'src/assets/images/icons/laptop.svg';
import medal from 'src/assets/images/icons/medal.svg';
import users from 'src/assets/images/icons/users.svg';
import imgAd from 'src/assets/images/anuncie-seu-imovel-anage.webp';
import imgStamp from 'src/assets/images/anage-desde-1866.webp';

export default function AdInfo() {
    return (
        <div className="info-ad">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className="title title-2">
                                    Anuncie seu Imóvel em Joinville com a Anagê
                                </h2>
                                <h3 className="title title-3">
                                    Aluguel e Venda
                                </h3>
                                <div className="block">
                                    <div className="item">
                                        <img alt="" src={fastForward} className="icon"></img>
                                        <span className="item-info">
                                            <strong>Agilidade: </strong>negociação e assinatura do contrato online.
                                        </span>
                                    </div>
                                    <div className="item">
                                        <img alt="" src={handshake} className="icon"></img>
                                        <span className="item-info">
                                            <strong>Assertividade: </strong>imobiliária que mais vende e aluga imóveis em Joinville e região.
                                        </span>
                                    </div>
                                    <div className="item">
                                        <img alt="" src={laptop} className="icon"></img>
                                        <span className="item-info">
                                            <strong>Divulgação: </strong>seu imóvel é anunciado no site da Anagê e principais portais do país.
                                        </span>
                                    </div>
                                    <div className="item">
                                        <img alt="" src={medal} className="icon"></img>
                                        <span className="item-info">
                                            <strong>Segurança: </strong>processos certificados e mais 30 anos fazendo negócios imobiliários em Joinville com ética, transparência e credibilidade.
                                        </span>
                                    </div>
                                    <div className="item">
                                        <img alt="" src={users} className="icon"></img>
                                        <span className="item-info">
                                            <strong>Atendimento completo: </strong>estrutura para dar todo o suporte do início ao fim da transação imobiliária.
                                        </span>
                                    </div>
                                </div>
                                <RouterLink to="/anuncie-seu-imovel" className="btn btn-1">Anunciar Imóvel</RouterLink>
                            </div>
                            <div className="col-md-6">
                                <div className="block-images">
                                    <img alt="" src={imgAd} className="img-fluid img-ad"></img>
                                    <div className="stamp">
                                        <img alt="" src={imgStamp} className="img-fluid img-stamp"></img>
                                        <span className="info">
                                            <strong>Confiança: </strong>mais de 30 anos de experiência e inovações, somos a principal autoridade no mercado imobiliário de Joinville e região.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}