import axios from 'src/utils/axios';

import ManagerApi from 'src/services/managerApi'

class AuthService {
	// setAxiosInterceptors = ({ onLogout }) => {
	//         axios.interceptors.response.use(
	//                 (response) => {
	//                         console.log(response);
	//                         return response;
	//                 },
	//                 (error) => {
	//                         if (error.response && error.response.status === 401) {
	//                                 this.setSession(null);
	//
	//                                 if (onLogout) {
	//                                         onLogout();
	//                                 }
	//                         }
	//
	//                         return Promise.reject(error);
	//                 }
	//         );
	// };

	handleAuthentication() {
		const accessToken = this.getAccessToken();

		if (!accessToken) {
			return;
		}

		if (this.isValidToken(accessToken)) {
			this.setSession(accessToken);
		} else {
			this.setSession(null);
		}
	}

	loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
		const managerApi = new ManagerApi('/auth');
		managerApi.post({ login: email, password })
			.then((response) => {
				if (response.data.content) {
					this.setSession(response.data.content.accessToken);
					resolve(response.data.content);
				} else {
					reject(response.data.error);
				}
			})
			.catch((error) => {
				reject(error);
			});
	})

	loginInWithToken = () => new Promise((resolve, reject) => {
		const managerApi = new ManagerApi('/auth');
		managerApi.get('1')
			.then((response) => {
				if (response.data.content) {
					this.setSession(response.data.content.accessToken);
					resolve(response.data.content);
				} else {
					resolve(false);
				}
			})
			.catch((error) => {
				reject(error);
			});
	})

	logout = () => {
		this.setSession(null);
	}

	setSession = (accessToken) => {
		if (accessToken) {
			localStorage.setItem('accessToken', accessToken);
			// axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
			axios.defaults.headers.common['X-Api-Key'] = accessToken;
		} else {
			localStorage.removeItem('accessToken');
			delete axios.defaults.headers.common.Authorization;
		}
	}

	getAccessToken = () => localStorage.getItem('accessToken');

	isValidToken = (accessToken) => {
		if (!accessToken) {
			return false;
		}

		return true;

		// const decoded = jwtDecode(accessToken);
		// const currentTime = Date.now() / 1000;
		//
		// return decoded.exp > currentTime;
	}

	isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
