import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import Link from '@material-ui/core/Link';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import logo from 'src/assets/images/logo-anage-rodape.svg';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';
import headset from 'src/assets/images/icons/icon-fale-conosco.svg';
import area from 'src/assets/images/icons/area-do-cliente.svg';
import linkedin from 'src/assets/images/icons/linkedin.svg';
import facebook from 'src/assets/images/icons/facebook-1.svg';
import instagram from 'src/assets/images/icons/instagram-1.svg';
import youtube from 'src/assets/images/icons/youtube-1.svg';

import { getWhatsappLink } from 'src/utils/functions';
import whatsappWhite from 'src/assets/images/icons/whatsapp-white.svg';
import avatar from 'src/assets/images/broker.jpeg';

function Footer({ className }) {
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const brokerInformation = useSelector((state) => state.interface.brokerInformation);
    const { trackEvent } = useAnalytics();

    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-6 col-md-3">
                                        <ul className="list">
                                            <li className="list-item first">
                                                <span>Imóveis</span>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/como-alugar">Como Alugar</RouterLink>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/como-comprar">Como Comprar</RouterLink>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/anuncie-seu-imovel">Anunciar Imóvel</RouterLink>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/perguntas-frequentes">Perguntas Frequentes</RouterLink>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/joinville">Joinville</RouterLink>
                                            </li>
                                        </ul>
                                        <ul className="list show-mobile">

                                        </ul>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <ul className="list">
                                            <li className="list-item first">
                                                <span>Proprietários</span>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/calculadora">Calculadora de Aluguel</RouterLink>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <ul className="list">
                                            <li className="list-item first">
                                                <span>Inquilinos</span>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/area-do-cliente/sou-inquilino">Aviso de Desocupação</RouterLink>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/area-do-cliente/sou-inquilino/manutencao">Manutenção</RouterLink>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/area-do-cliente/sou-inquilino">Fundo de Reservas e Taxas</RouterLink>
                                            </li>
                                            {/* <li className="list-item">
                                                <RouterLink to="/area-do-cliente/sou-inquilino">Contestações e Vistorias</RouterLink>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <ul className="list">
                                            <li className="list-item">
                                                <span>Institucional</span>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/institucional">Sobre Nós</RouterLink>
                                            </li>
                                            <li className="list-item">
                                                <Link href="https://anageimoveis.solides.jobs/" target="_blank">Trabalhe Conosco</Link>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/contato">Contato</RouterLink>
                                            </li>
                                            <li className="list-item">
                                                <RouterLink to="/blog">Blog</RouterLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-md-12">
                                <RouterLink to="/contato" className="btn btn-1">
                                    <img alt="" src={headset} className="icon"></img>
                                    <span>Fale Conosco</span>
                                </RouterLink>
                                <RouterLink to="/area-do-cliente" className="btn btn-2">
                                    <img alt="" src={area} className="icon"></img>
                                    <span>Área do Cliente</span>
                                </RouterLink>
                            </div> */}

                        </div>
                    </div>
                    <div className="content">
                        <div className="row">

                            {generalInformation.stores && generalInformation.stores.map((store, key) => (
                                <div key={key} className="col-6 col-md-3">
                                    <div className="item">
                                        <h5 className="title title-5">{store.name}</h5>
                                        <a href={"tel:" + store.phone}>{store.phone}</a>
                                        <a href={getWhatsappLink(store.whatsapp)} target="_blank"
                                            onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', store.whatsapp) }}
                                        >
                                            <span>{store.whatsapp}</span><img alt="" src={whatsapp} className="icon"></img>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="item">
                                    <h5 className="title title-5">Horário de funcionamento</h5>
                                    <p className="text text-1">
                                        <span>Segunda a Sexta - 8h às 19h</span>
                                        <span>Sábado - 8h às 12:30h (plantão comercial)</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="block">
                                    <ul className="social-media">
                                        <li className="list-item">
                                            <a href="https://www.linkedin.com/company/anage-imoveis-ltda/" target="_blank" className="list-link linkedin">
                                                <img alt="" src={linkedin} className="icon"></img>
                                            </a>
                                        </li>
                                        <li className="list-item">
                                            <a href="https://www.instagram.com/anageimoveis/" target="_blank" className="list-link instagram">
                                                <img alt="" src={instagram} className="icon"></img>
                                            </a>
                                        </li>
                                        <li className="list-item">
                                            <a href="https://www.facebook.com/anage.imoveis/?ref=br_rs" target="_blank" className="list-link facebook">
                                                <img alt="" src={facebook} className="icon"></img>
                                            </a>
                                        </li>
                                        <li className="list-item">
                                            <a href="https://www.youtube.com/user/anagetv" target="_blank" className="list-link youtube">
                                                <img alt="" src={youtube} className="icon"></img>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="logo">
                                        <img alt="" src={logo} className="img-logo"></img><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="info">
                                    <p className="text">
                                        <span>2024 © Anagê Imóveis. Todos os direitos reservados &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; </span><RouterLink to="/politica-de-privacidade">Política de Privacidade</RouterLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


            {brokerInformation ? (
                <div className="broker-floating">
                    <RouterLink to={"/corretores/" + brokerInformation.broker.slug}>
                        <div className="broker-header">
                            <div className="broker-image" style={{ backgroundImage: 'url(' + brokerInformation.broker.photo + ')' }}></div>
                            <div className="broker-name">{brokerInformation.broker.name}</div>
                        </div>
                    </RouterLink>
                    {brokerInformation.broker.cellphone && (
                        <a href={getWhatsappLink(brokerInformation.broker.cellphone)} target="_blank" className="btn btn-whats"
                            onClick={() => { trackEvent('Clique no botão', 'Whatsapp Corretor', brokerInformation.broker.cellphone) }}
                        >
                            <img alt="" src={whatsapp} className="icon"></img>
                            Fale pelo WhatsApp
                        </a>
                    )}
                </div>
            ) : (
                <>
                    <a href={getWhatsappLink("47996531009")} target="_blank" className="btn btn-whats-floating d-none"
                        onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', '47996531009') }}
                    >
                        <img alt="" src={whatsappWhite} className="icon"></img>
                    </a>
                    <div id="zoe-bot"></div>
                </>
            )}


        </div>
    );
}

Footer.propTypes = {
    className: PropTypes.string
};

export default Footer;
