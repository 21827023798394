import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Header from 'src/layouts/MainLayout/Header.js';
import Footer from 'src/layouts/MainLayout/Footer.js';
import GlobalLoading from 'src/components/GlobalLoading';
import GlobalMessage from 'src/components/GlobalMessage';

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

function MainLayout({ children }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Header />
            <div className="page-content" id="page-content">
                {children}
            </div>
            <Footer />
            <GlobalLoading />
            <GlobalMessage />
        </div>
    );
}

MainLayout.propTypes = {
    children: PropTypes.any
};

export default MainLayout;
