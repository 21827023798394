import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink} from 'react-router-dom';
import { useHistory } from 'react-router';

import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';

import CardProperty from 'src/components/AnageImoveis/CardProperty';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import background from 'src/assets/images/AdobeStock_329574014.webp';
import { Button } from '@material-ui/core';

export default function SearchResults({realEstates, filters, pageTitle, paginator, onFilter, onSort, onPaginate, compact=false}) {

    const generalInformation = useSelector((state) => state.interface.generalInformation);

    const generatePages = () => {
        let maxPages = 10;
        let startPage = paginator.pageCurrent - (maxPages/2);
        let pages = [];
        // let url = '//' + window.location.host + window.location.pathname;
        // to={url+"?pagina="+i}
        for(let i=1; i<=paginator.pageLast; i++) {
            if(i<startPage) {
                continue;
            }
            pages.push(
                <li className="page-number" key={i}>
                    <RouterLink onClick={ () => onPaginate(i) } className={`link ${paginator.pageCurrent === i ? " active" : ""}`}>
                        {i}
                    </RouterLink>
                </li>
            );
            if(pages.length >= maxPages) {
                break;
            }
        }
        return pages;
    }

    const isSubcategoryChecked = (subcategory) => {
        let checked = false;

        if(filters.subcategory) {
            filters.subcategory.map((item) => {
                if(subcategory.id == item) {
                    checked = true;
                }
            });
        }

        return checked;
    }

    const clearSubcategory = () => {
        let newFilters = {...filters};

        if(newFilters.subcategory) {
            delete newFilters.subcategory;
        }

        onFilter(newFilters);
    }

    const filterSubcategory = (subcategory) => {
        let newFilters = {...filters};

        if(!newFilters.subcategory) {
            newFilters.subcategory = [];
        }

        if(isSubcategoryChecked(subcategory)) {
            newFilters.subcategory = newFilters.subcategory.filter( (item) => { return item !== subcategory.id } );
        } else {
            newFilters.subcategory.push(subcategory.id);
        }

        onFilter(newFilters);
    }

    let title = '';
    let categoryFilter = null;
    if(pageTitle) {
        title = pageTitle;
    }

    if(filters.category) {
        generalInformation.categories[filters.status.toLowerCase()].map(category => {
            if(category.id == filters.category) {
                categoryFilter = category;
                console.log(category)
            }
        })
    }

    if(!realEstates) {
        return <></>;
    }

    return (
        <>
            <div id="property-list" className="property-list">
                <div className="filters-info">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="block-left">
                                <h1 className="title title-4">
                                    {title}
                                </h1>
                                <p className="text text-1">
                                    {paginator.totalItemCount} Resultados
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="block-right">
                                <span className="text text-1">Ordenar por:</span>
                                <FormControl className="order-select">
                                    <NativeSelect onChange={ (e) => onSort(e.target.value) }>
                                        <option value="relevance">Relevância</option>
                                        <option value="priceDesc">Valor Maior</option>
                                        <option value="priceAsc" selected>Valor Menor</option>
                                        <option value="areaDesc">Área Maior</option>
                                        <option value="areaAsc">Área Menor</option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                {categoryFilter && (
                    <div className="sub-filters">
                        <div className="line">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="text text-1">
                                        Tipos de {categoryFilter.name}:
                                    </h2>
                                    <div className="filter-items">
                                        <div className={ (!filters.subcategory || filters.subcategory.length == 0) ? "filter-item active" : "filter-item" } onClick={ () => { clearSubcategory() }}>
                                            Todos
                                        </div>
                                        {generalInformation.categories[filters.status.toLowerCase()].map(category => {
                                            if(category.parent_id == categoryFilter.id) {
                                                return (
                                                    <div className={ isSubcategoryChecked(category) ? "filter-item active" : "filter-item" } onClick={ () => { filterSubcategory(category) }}>
                                                        {category.name}
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    {realEstates.map( (realEstate, key) => (
                        <div key={realEstate.code} className="col-md-4">
                            <CardProperty realEstate={realEstate} />
                        </div>
                    ))}
                </div>
                {paginator && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pagination">
                                <ul className="pagination-block">
                                    <li className="page-number left">
                                        <RouterLink onClick={ () => onPaginate(paginator.pageCurrent-1) } className="link">
                                            <span className="icon icon-left-arrow"></span>
                                        </RouterLink>
                                    </li>
                                    {generatePages()}
                                    <li className="page-number right">
                                        <RouterLink onClick={ () => onPaginate(paginator.pageCurrent+1) } className="link">
                                            <span className="icon icon-right-arrow"></span>
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-md-12">
                        <div className="banner" style={{ backgroundImage: 'url(' + background + ')' }}>
                            <div className="description">
                                <h3 className="title title-3">
                                    Não encontrou o que procurava?
                                </h3>
                                <p className="text text-1">
                                    Procurando imóvel em Joinville? Conte com a Anagê para encontrar o imóvel ideal para você!
                                </p>
                                <RouterLink to="/encontrar-meu-imovel" className="btn btn-1">
                                    Quero Ajuda
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
