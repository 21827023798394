import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import close from 'src/assets/images/icons/close.svg';

import { formatPrice } from 'src/utils/functions.js';

export default function FavoriteModal() {
    const [closeModal, setCloseModal] = useState(false);

    // if(!realEstate) {
    //     return null;
    // }

    // let images = [];

    // images = realEstate.gallery.slice(0,5);

    // let realEstateStatus = realEstate.status.charAt(0).toUpperCase() + realEstate.status.slice(1)

    return (
        <>
        {closeModal && (
            <div className="favorite-modal">
                <h4 className="title title-3">Imóveis Favoritos</h4>
                <button type="button" className="btn btn-close" onClick={ () => setCloseModal(!closeModal) }>
                    <img alt="" src={close} className="img"></img>
                </button>
                <div className="properties-block">
                    <div className="property-item">
                        <div className="item">
                            <div className="image" style={{ backgroundImage: 'url()' }}>
                                <div className="modality">Alugar</div>
                            </div>
                            <div className="info">
                                <p className="text text-6">Apartamento</p>
                                <p className="text text-6">Centro - Joinville</p>
                                <p className="text text-1">R$ 2.000,00</p>
                            </div>
                        </div>
                        <button type="button" className="btn btn-remove">Remover</button>
                    </div>
                    <div className="property-item">
                        <div className="item">
                            <div className="image" style={{ backgroundImage: 'url()' }}>
                                <div className="modality">Alugar</div>
                            </div>
                            <div className="info">
                                <p className="text text-6">Apartamento</p>
                                <p className="text text-6">Centro - Joinville</p>
                                <p className="text text-1">R$ 2.000,00</p>
                            </div>
                        </div>
                        <button type="button" className="btn btn-remove">
                            Remover
                        </button>
                    </div>
                    <div className="info-favorite">
                        <p className="text text-6">
                            Você tem mais 2 imóveis favoritados
                        </p>
                    </div>
                    <RouterLink to="/" className="btn btn-3">Ver Todos</RouterLink>
                </div>
            </div>
        )}
        </>
    );
}