
export function formatPrice(price) {

    var locale = 'pt-br';
    var currencyCode = 'BRL';

    return Number(price).toLocaleString(locale, {style: 'currency', currency: currencyCode});;
}

export function getWhatsappLink(phone) {

    const mobileSize = window.innerWidth < 700;
    let linkWhatsapp;

    if (mobileSize) {
        linkWhatsapp = 'https://api.whatsapp.com/send/?phone=+55' + phone.match(/\d+/g).join([]);
    }
    else {
        linkWhatsapp = 'https://web.whatsapp.com/send?phone=+55' + phone.match(/\d+/g).join([]);
    }

    return linkWhatsapp;
}

export function getYoutubeEmbedData(youtubeUrl) {
    let videoId = "";
    
    if (videoId = youtubeUrl.match(/(\?|&)v=([^&#]+)/)) {
        videoId = videoId.pop();
    } else if (videoId = youtubeUrl.match(/(\.be\/)+([^\/]+)/)) {
        videoId = videoId.pop();
    } else if (videoId = youtubeUrl.match(/(\embed\/)+([^\/]+)/)) {
        videoId = videoId.pop().replace('?rel=0', '');
    }
    
    if (videoId) {
        return {
            image: "https://img.youtube.com/vi/" + videoId + "/hqdefault.jpg",
            url: "https://www.youtube.com/embed/" + videoId
        }
    }

    return {
        image: '',
        url: ''
    };
}

