import React, {
        useState,
        useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
        Box,
        Button,
        Portal,
        Typography,
        makeStyles
} from '@material-ui/core';
import { Underline } from 'react-feather';

const useStyles = makeStyles((theme) => ({
        root: {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
                maxWidth: 1100,
                width: '100%',
                position: 'fixed',
                bottom: '35px',
                left: '50%',
                marginLeft: '-550px',
                padding: '15px 30px',
                outline: 'none',
                zIndex: 2000,
                borderRadius: '10px',
                boxShadow: '0px 5px 10px #0000001A',
                display: 'flex',
                [theme.breakpoints.down("md")]: {
                        maxWidth: '100%',
                        width: 'auto',
                        left: '15px',
                        right: '15px',
                        bottom: '15px',
                        margin: '0'
                },
                [theme.breakpoints.down("sm")]: {
                        display: 'block'
                },
        },
        p: {
                fontFamily: '"poppinsregular", sans-serif',
                color: '#1A1818',
                fontSize: '13px',
                [theme.breakpoints.down("sm")]: {
                        marginBottom: '15px'
                },
        },
        link: {
                fontFamily: '"poppinsregular", sans-serif',
                color: '#30A0EB',
                fontSize: '13px',
        },
        action: {
                fontFamily: '"poppinsregular", sans-serif',
                fontSize: '13px',
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
                width: '200px',
                textTransform: 'none',
                margin: '0',
                boxShadow: 'none',
                border: '1px solid #FFBB00',
                [theme.breakpoints.down("sm")]: {
                        width: '100%'
                },
        }
}));

function CookiesNotification() {
        const classes = useStyles();
        const [open, setOpen] = useState(false);

        const handleClose = () => {
                Cookies.set('consent', 'true');
                setOpen(false);
        };

        useEffect(() => {
                const consent = Cookies.get('consent');

                if (!consent) {
                        setOpen(true);
                }
        }, []);

        if (!open) {
                return null;
        }

        return (
                <Portal>
                        <div className={classes.root}>
                                <Typography
                                        variant="body1"
                                        color="inherit"
                                        className={classes.p}
                                >
                                        <strong>Anagê e os cookies:</strong> Utilizamos cookies para personalizar anúncios e melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa
                                        {' '}
                                        <RouterLink
                                                className={classes.link}
                                                to="/politica-de-privacidade"
                                                target="_blank"
                                        >
                                                clique aqui
                                        </RouterLink>
                                        .
                                </Typography>
                                <Box
                                        display="flex"
                                >
                                        <Button
                                                onClick={handleClose}
                                                variant="contained"
                                                className={classes.action}
                                        >
                                                Aceitar e fechar
                                        </Button>
                                </Box>
                        </div>
                </Portal>
        );
}

export default CookiesNotification;
