import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import InterestSearchBlock from 'src/components/AnageImoveis/InterestSearchBlock';
import CardProperty from 'src/components/AnageImoveis/CardProperty';

import { v4 as uuidv4 } from 'uuid';
import ManagerApi from 'src/services/managerApi';
import { useDispatch, useSelector } from 'react-redux';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';

import image from 'src/assets/images/banner.jpg';
import car from 'src/assets/images/icons/car.svg';
import area from 'src/assets/images/icons/icon-area.svg';
import bed from 'src/assets/images/icons/bed.svg';
import like from 'src/assets/images/icons/like.svg';
import likeFilled from 'src/assets/images/icons/like-filled.svg';
import home from 'src/assets/images/icons/home.svg';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const useStyles = makeStyles(() => ({
    root: {}
}));

function FindProperty() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const interfaceStore = useSelector((state) => state.interface);
    const realEstateApi = new ManagerApi('/realestate');

    const [realEstates, setRealEstates] = useState(false);

    const fetchRealEstate = () => {
        const requestId = uuidv4();
        
        if(interfaceStore.favorites.length) {
            dispatch(addLoadingGlobal(requestId));
            realEstateApi.getList({
                ids: interfaceStore.favorites,
                paginator: {
                    pageCurrent: 1,
                    pageItemsPerPage: 50
                }
            }).then((response) => {
                if (response.data.success) {
                    setRealEstates(response.data.content)
                }
            }).catch((error) => {
            }).then(() => {
                dispatch(removeLoadingGlobal(requestId));
            });
        }
        else {
            setRealEstates([])
        }

    }

    useEffect(() => {
        fetchRealEstate();
    }, [interfaceStore.favorites]);

    if(realEstates === false) {
        return (<></>);
    }

    let pageTitle = 'Imóveis Favoritos';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];


    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="favorite-properties">
                <div className="description">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <h2 className="title title-1">
                                    Imóveis Favoritos
                                </h2>
                                <p className="text text-1">
                                    Confira aqui as opções que você selecionou como Imóveis Favoritos para locação e venda em Joinville e região e aproveite para agendar sua visita!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    
                    <div className="properties-list">
                        <div className="row">

                            {realEstates.length > 0 && realEstates.map( realEstate => (
                                <div className="col-md-4">
                                    <CardProperty realEstate={realEstate} />
                                </div>
                            ))}

                            {realEstates.length == 0 && (
                                <div className="col-md-12">
                                    <div className="favorite-empty">
                                        <div className="row">
                                            <div className="col-md-4 offset-md-2">
                                                <h3 className="title title-2">
                                                    Não há favoritos adicionados
                                                </h3>
                                            </div>
                                            <div className="col-md-4">
                                                <p className="text text-1">
                                                    Você pode adicionar imóveis aos favoritos clicando no ícone:

                                                    <img alt="" src={like} className="icon"></img>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                
                <div className="popular-search">
                    <InterestSearchBlock />
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default FindProperty;
