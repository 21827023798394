import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import image from 'src/assets/images/recebemos-sua-mensagem-anage.svg';

import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {}
}));

function Success() {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Obrigado"
        >
            <div className="success-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 offset-md-2">
                            <img alt="" src={image} className="image img-fluid"></img>
                        </div>
                        <div className="col-md-4">
                            <div className="description">
                                <div className="info-centered">
                                    <h2 className="title title-1">
                                        Mensagem enviada com sucesso!
                                    </h2>
                                    <p className="text text-1">
                                        Em breve, retornaremos o contato.
                                    </p>
                                    <div className="form-row">
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <RouterLink to="/" className="btn btn-1">
                                                <span className="icon"></span>
                                                Voltar
                                            </RouterLink>
                                        </div>
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <RouterLink to="/" className="btn btn-3">
                                                Home
                                            </RouterLink>
                                        </div>
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <RouterLink to="/como-alugar" className="btn btn-3">
                                                Como Alugar
                                            </RouterLink>
                                        </div>
                                        <div className="col-6 col-md-12 col-lg-6">
                                            <RouterLink to="/como-comprar" className="btn btn-3">
                                                Como Comprar
                                            </RouterLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default Success;
