import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import car from 'src/assets/images/icons/car.svg';
import area from 'src/assets/images/icons/icon-area.svg';
import bed from 'src/assets/images/icons/bed.svg';
import like from 'src/assets/images/icons/like.svg';
import likeFilled from 'src/assets/images/icons/like-filled.svg';

import SwiperCore, { Virtual, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatPrice } from 'src/utils/functions.js';

import { useDispatch, useSelector } from 'react-redux';
import { addFavorite, removeFavorite } from 'src/actions';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual]);

const RealEstateLink = ({realEstate, children}) => {
    let linkUrl = '/' + realEstate.slug;
    const savedSearch = sessionStorage.getItem('savedSearch');
    if (savedSearch) {
        linkUrl = '/' + realEstate.slug + savedSearch;
    } 

    let linkExt = false;

    // realEstateOrigin
    // console.log('LISTA');
    // console.log(realEstate.origin);
    console.log(realEstate);

    if (realEstate.origin && realEstate.codigoEmp != "") {
        // https://lancamentos.anageimoveis.com.br/?lancamentoid=
        linkExt = true;
        linkUrl = 'https://lancamentos.anageimoveis.com.br/?lancamentoid=' + realEstate.codigoEmp;
    } else if (realEstate.tagSelect) {
        linkExt = true;
        linkUrl = 'https://anageselect.com.br' + linkUrl;
    }
  

  return (
    <>
      {  (linkExt) ? (
        <Link href={linkUrl} target="_blank">
          {children}
        </Link>
      ) : (
        <RouterLink to={linkUrl} target="_blank">
          {children}
        </RouterLink>
      )

      }

      {/*(realEstate.tagSelect) ? (
        <Link href={linkUrl} target="_blank">
          {children}
        </Link>
      ) : (
        <RouterLink to={linkUrl} target="_blank">
          {children}
        </RouterLink>
      )*/}
    </>
  )

}

export default function CardProperty({realEstate, gallery = true}) {

    const interfaceStore = useSelector((state) => state.interface);
    const dispatch = useDispatch();
    
    const [swiperInstance, setSwiperInstance] = useState(null);

    if(!realEstate) {
        return null;
    }
    
    let images = realEstate.gallery;
    let realEstateStatus = realEstate.status.charAt(0).toUpperCase() + realEstate.status.slice(1);
    let realEstateOrigin = realEstate.origin;
    let realEstateExchange = realEstate.exchange;

    const isFavorite = (id) => {
        let found = false;
        for (let i = 0; i < interfaceStore.favorites.length; i++) {
            if (interfaceStore.favorites[i] === id) {
                found = true;
            }
        }
        return found;
    }

    return (
        <div className="card card-property">
            <RealEstateLink realEstate={realEstate}>
                <div className="property-images">
                    {gallery ? (
                        <>
                            <Swiper
                                slidesPerView={1}
                                onSwiper={(swiper) => { setTimeout( () => {swiper.update();}, 200); setSwiperInstance(swiper) } }
                                virtual
                            >
                                {images.map( (image, index) => (
                                    <SwiperSlide key={index} virtualIndex={index}>
                                        <div className="image" style={{ backgroundImage: 'url(' + image.image + ')' }}></div>
                                        <span className="count">{index+1}/{images.length}</span>
                                    </SwiperSlide>
                                ))}
                                <div className="swiper-button-prev" onClick={ (e) => { e.preventDefault(); swiperInstance.slidePrev() }}></div>
                                <div className="swiper-button-next"onClick={ (e) => { e.preventDefault(); swiperInstance.slideNext() }}></div>
                                <div className="tags">
                                    {realEstateExchange && (
                                        <div className="tag yellow">Permuta</div>
                                    )}
                                    {realEstateOrigin && (
                                        <div className="tag yellow">{realEstateOrigin}</div>
                                    )}
                                    <div className="tag black">{realEstateStatus}</div>
                                </div>
                            </Swiper>
                        </>
                    ) : (
                        <>
                            <div className="swiper-slide">
                                <div className="image" style={{ backgroundImage: 'url(' + images[0].image + ')' }}></div>
                            </div>
                            <div className="tags">
                                {realEstateExchange && (
                                    <div className="tag yellow">Permuta</div>
                                )}
                                {realEstateOrigin && (
                                    <div className="tag yellow">{realEstateOrigin}</div>
                                )}
                                <div className="tag black">{realEstateStatus}</div>
                            </div>
                        </>

                    )}
                </div>
            </RealEstateLink>
            <div className="description">
                <div className="info top-info">
                    <RealEstateLink realEstate={realEstate}>
                        <h2 className="block-title">
                            <span className="title">{realEstate.category && realEstate.category.name}</span>
                            <span className="subtitle">{realEstate.neighbourhood} - {realEstate.city}</span>
                        </h2>
                    </RealEstateLink>
                    <div className="icons">
                        { isFavorite(realEstate.id) ? (
                            <img alt="" src={likeFilled} className="icon filled" onClick={ () => { dispatch(removeFavorite(realEstate.id)) }} />
                        ) : (
                            <img alt="" src={like} className="icon linear" onClick={ () => { dispatch(addFavorite(realEstate.id)) }} />
                        )}
                    </div>
                </div>
                <div className="info middle-info">
                    <div>
                        {realEstate.status === 'comprar' && realEstate.priceSaleSpecial > 0 ? (
                            <>
                                <span className="priceOld">{formatPrice(realEstate.priceSale)}</span>
                                <span className="price">{formatPrice(realEstate.priceSaleSpecial)}</span>
                            </>
                        ) : (
                            <>
                                <span className="price">{formatPrice(realEstate.status === 'comprar' ? realEstate.priceSale : realEstate.priceRental)}</span>
                            </>
                        )}
                    </div>
                    <span className="ref">Ref.: {realEstate.code}</span>
                </div>
                <div className="info bottom-info">
                    <div className="feature">
                        {realEstate.bedrooms !== '0' && (
                            <>
                                <img alt="" src={bed} className="icon"></img>
                                <span className="info-feature">{realEstate.bedrooms} quartos</span>
                            </>
                        )}
                    </div>
                    <div className="feature">
                        <img alt="" src={area} className="icon"></img>
                        <span className="info-feature">{Number.parseFloat(realEstate.area).toFixed(0)}m²</span>
                    </div>
                        <div className="feature">
                            {realEstate.parkingSpaces !== '0' && (
                                <>
                                    <img alt="" src={car} className="icon"></img>
                                    <span className="info-feature">{realEstate.parkingSpaces} vagas</span>
                                </>
                            )}
                        </div>
                </div>
            </div>
        </div>
    );
}
